import React from 'react';

import { Container, Divider, Grid, Tooltip, Typography } from '@mui/material';

import IconButtonLink from '../Buttons/IconButtonLink/IconButtonLink';
import TrackedNavLinkButton from '../Buttons/TrackedNavLinkButton/TrackedNavLinkButton';
import { TrackedActionContext } from '../TrackedActions/trackedAction.context';
import { TrackEventName } from '../TrackedActions/withTrackedAction.interface';

import { useStyles } from './styles';
import { useFooter } from './useFooter';

export const Footer = () => {
  const classes = useStyles();
  const {
    footer: { copyright, icons, links },
    isHidden,
  } = useFooter();

  if (isHidden) return null;

  return (
    <TrackedActionContext.Provider value={{ actionContext: 'Footer' }}>
      <footer className={classes.wrapper}>
        <Divider />

        <Container className={classes.container} maxWidth="xl">
          <Grid alignItems="center" spacing={2} container>
            <Grid className={classes.navigation} item xs>
              {links.map((link) => (
                <TrackedNavLinkButton
                  activeClassName={classes.navLinkActive}
                  eventName={TrackEventName.FooterLinkClicked}
                  href={link.url}
                  key={link.url}
                  size="small"
                  to={`${link.url}`}
                >
                  {link.label}
                </TrackedNavLinkButton>
              ))}
            </Grid>

            <Grid justifyContent="flex-end" container item xs>
              <Grid item>
                {icons.map((icon) => (
                  <IconButtonLink
                    eventName={TrackEventName.FooterIconClicked}
                    href={icon.url}
                    key={icon.url}
                  >
                    <img alt="" height={20} src={icon.icon} width={20} />
                  </IconButtonLink>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>

        <div className={classes.disclaimer}>
          <Container>
            <Tooltip title={window.REACT_APP_VERSION}>
              <Typography color="textSecondary" variant="body2">
                {copyright}
              </Typography>
            </Tooltip>
          </Container>
        </div>
      </footer>
    </TrackedActionContext.Provider>
  );
};
