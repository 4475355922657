import { useSelector } from 'react-redux';

import { selectUser } from 'containers/User/user.slice';

import { useAssertTenantSettings } from './useAssertTenantSettings';
import { useSearchTabAvailability } from './useSearchTabAvailability';

// TODO: Add place of usage
export const useChatAvailability = () => {
  const userSettings = useSelector(selectUser);
  const { tenantSettings } = useAssertTenantSettings();
  const { isGoogleScholarTab } = useSearchTabAvailability();
  const chatSettings = tenantSettings.chat;

  return {
    isChatAvailable:
      !!userSettings?.useOpenAI &&
      !!chatSettings &&
      // isChatAvailableForTenant(chatSettings) &&
      !isGoogleScholarTab,
  };
};
