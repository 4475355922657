import React, { useState } from 'react';

import { Add as AddIcon } from '@mui/icons-material';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  DialogActions,
  Button,
  Switch,
  Typography,
  Divider,
  Box,
  useTheme,
  alpha,
  TextField,
  Tabs,
  Tab,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { BotConfiguration } from 'api/tenantSettingsApi/tenantSettingsApi.types';
import { removeHtmlTags } from 'common/utils/removeHtmlTags';
import { PartialRecord } from 'common/utils/types.helpers';

interface BotConfigDialogProps {
  allBotTypes: string[];
  botIdentifierToConfig?: PartialRecord<string, BotConfiguration>;
  onClose: () => void;
  onCreateCustomBot?: (bot: BotConfiguration) => void;
  onToggleBot: (bot: BotConfiguration) => void;
  open: boolean;
  visibleBots: Set<string>;
}

export const BotConfigDialog: React.FC<BotConfigDialogProps> = ({
  allBotTypes,
  botIdentifierToConfig,
  onClose,
  onCreateCustomBot,
  onToggleBot,
  open,
  visibleBots,
}) => {
  const { t } = useTranslation('chat');
  const theme = useTheme();
  const [isCustomBotDialogOpen, setIsCustomBotDialogOpen] = useState(false);

  const handleOpenCreateCustomBot = () => {
    setIsCustomBotDialogOpen(true);
  };

  const handleCloseCreateCustomBot = () => {
    setIsCustomBotDialogOpen(false);
  };

  // Helper function to safely display text
  const safeText = (text: string): string => {
    return removeHtmlTags(text || '');
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={open}
        PaperProps={{
          sx: {
            borderRadius: 2,
            overflow: 'hidden',
          },
        }}
        fullWidth
        onClose={onClose}
      >
        <DialogTitle
          sx={{
            borderBottom: `1px solid ${theme.palette.divider}`,
            pb: 2,
            pt: 3,
            px: 3,
          }}
        >
          <Typography fontWeight="500" variant="h5">
            {t('chat:botSelector.title')}
          </Typography>
          <Typography color="text.secondary" sx={{ mt: 0.5 }} variant="body2">
            {t('chat:botSelector.description')}
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ p: 0 }}>
          <List sx={{ py: 0 }}>
            {allBotTypes.map((botType, index) => {
              if (!botIdentifierToConfig) return null;
              const config = botIdentifierToConfig[botType];
              const label = safeText(config?.label || botType);
              const description = safeText(config?.description || '');
              const icon = config?.icon;
              const isChecked = visibleBots.has(botType);

              return (
                <React.Fragment key={botType}>
                  <ListItem
                    sx={{
                      '&:hover': {
                        bgcolor: isChecked
                          ? alpha(theme.palette.primary.main, 0.08)
                          : alpha(theme.palette.action.hover, 0.08),
                      },
                      alignItems: 'center',
                      bgcolor: isChecked
                        ? alpha(theme.palette.primary.main, 0.04)
                        : 'transparent',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      px: 3,
                      py: 1.5,
                    }}
                    disablePadding
                    onClick={() => {
                      const botConfig = botIdentifierToConfig[botType];
                      if (botConfig) {
                        onToggleBot(botConfig);
                      }
                    }}
                  >
                    <Box
                      sx={{
                        alignItems: 'flex-start',
                        display: 'flex',
                        flexDirection: 'row',
                        maxWidth: 'calc(100% - 60px)',
                      }}
                    >
                      {icon && (
                        <Box
                          sx={{
                            alignItems: 'center',
                            bgcolor: alpha(theme.palette.primary.main, 0.08),
                            borderRadius: 1,
                            display: 'flex',
                            flexShrink: 0,
                            height: 40,
                            justifyContent: 'center',
                            mr: 2,
                            width: 40,
                          }}
                        >
                          <img
                            alt={`${label} icon`}
                            src={icon}
                            style={{
                              height: '24px',
                              objectFit: 'contain',
                              width: '24px',
                            }}
                          />
                        </Box>
                      )}
                      <Box sx={{ overflow: 'hidden' }}>
                        <Typography
                          fontWeight={isChecked ? 500 : 400}
                          variant="body1"
                        >
                          {label}
                        </Typography>
                        {description && (
                          <Typography
                            color="text.secondary"
                            sx={{
                              display: 'block',
                              mt: 0.5,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                            variant="body2"
                          >
                            {description}
                          </Typography>
                        )}
                      </Box>
                    </Box>

                    <Switch
                      checked={isChecked}
                      color="primary"
                      edge="end"
                      sx={{
                        flexShrink: 0,
                        ml: 2,
                      }}
                      onChange={() => {
                        const botConfig = botIdentifierToConfig[botType];
                        if (botConfig) {
                          onToggleBot(botConfig);
                        }
                      }}
                    />
                  </ListItem>
                  {index < allBotTypes.length - 1 && <Divider />}
                </React.Fragment>
              );
            })}
          </List>

          <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
            <Button
              color="primary"
              startIcon={<AddIcon />}
              sx={{
                borderRadius: 1.5,
                fontWeight: 500,
                textTransform: 'none',
              }}
              variant="outlined"
              onClick={handleOpenCreateCustomBot}
            >
              {t('chat:botSelector.createCustomBot')}
            </Button>
          </Box>
        </DialogContent>

        <DialogActions
          sx={{ borderTop: `1px solid ${theme.palette.divider}`, px: 3, py: 2 }}
        >
          <Button
            sx={{
              borderRadius: 1.5,
              fontWeight: 500,
              minWidth: 100,
              textTransform: 'none',
            }}
            variant="contained"
            onClick={onClose}
          >
            {t('chat:botSelector.close')}
          </Button>
        </DialogActions>
      </Dialog>

      {onCreateCustomBot && (
        <CustomBotDialog
          open={isCustomBotDialogOpen}
          onClose={handleCloseCreateCustomBot}
          onCreateBot={(newBot) => {
            onCreateCustomBot(newBot);
            handleCloseCreateCustomBot();
          }}
        />
      )}
    </>
  );
};

interface CustomBotDialogProps {
  onClose: () => void;
  onCreateBot: (bot: BotConfiguration) => void;
  open: boolean;
}

const CustomBotDialog: React.FC<CustomBotDialogProps> = ({
  onClose,
  onCreateBot,
  open,
}) => {
  const { t } = useTranslation('chat');
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);

  // Case 1: My own bot
  const [botName, setBotName] = useState('');
  const [botDescription, setBotDescription] = useState('');
  const [instructions, setInstructions] = useState('');

  // Case 2: Zeta Alpha agent
  const [zaInstructions, setZaInstructions] = useState('');
  const [selectedSources, setSelectedSources] = useState<string[]>([]);
  const [zaBotName, setZaBotName] = useState('Zeta Alpha Agent');

  // Case 3: Agents SDK
  const [agentIdentifier, setAgentIdentifier] = useState('');
  const [sdkBotName, setSdkBotName] = useState('');
  const [sdkBotDescription, setSdkBotDescription] = useState('');

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleSourceToggle = (source: string) => {
    setSelectedSources((prev) =>
      prev.includes(source)
        ? prev.filter((s) => s !== source)
        : [...prev, source]
    );
  };

  const sanitizeInput = (input: string): string => {
    // Remove potentially malicious HTML tags
    return removeHtmlTags(input);
  };

  const handleSubmit = () => {
    let newBot: BotConfiguration;

    switch (tabValue) {
      case 0: // My own bot
        if (!botName.trim()) return;

        newBot = {
          botParams: {
            description: sanitizeInput(botDescription),
            instructions,
            name: sanitizeInput(botName),
          },
          description: sanitizeInput(botDescription),
          identifier: 'custom_agent',
          label: sanitizeInput(botName),
          tenantBotType: 'custom_agent',
        };
        break;

      case 1: {
        // Zeta Alpha agent
        const tools = [];
        if (selectedSources.includes('My Data')) {
          tools.push({
            agent_tool_name: 'search_context_in_internal_index',
            description:
              'Search in the whole internal index for relevant context.',
            display_name: 'Internal search',
            enabled: true,
          });
        }
        if (selectedSources.includes('All the Web')) {
          tools.push({
            agent_tool_name: 'search_web',
            description: 'Search the web for relevant context.',
            display_name: 'Web search',
            enabled: true,
          });
        }
        if (selectedSources.includes('All of Science')) {
          tools.push({
            agent_tool_name: 'search_academic',
            description:
              'Search the academic google scholar for relevant context.',
            display_name: 'Scholar search',
            enabled: true,
          });
        }

        newBot = {
          botParams: {
            instructions: zaInstructions,
            tools,
          },
          description: 'Zeta Alpha agent with customized search capabilities',
          identifier: 'zav_orchestrator',
          label: sanitizeInput(zaBotName),
          tenantBotType: 'zav_orchestrator',
        };
        break;
      }

      case 2: // Agents SDK
        if (!agentIdentifier.trim() || !sdkBotName.trim()) return;

        newBot = {
          botParams: {},
          description: sanitizeInput(sdkBotDescription),
          identifier: agentIdentifier,
          label: sanitizeInput(sdkBotName),
          tenantBotType: agentIdentifier,
        };
        break;

      default:
        return;
    }

    onCreateBot(newBot);
  };

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      PaperProps={{
        sx: {
          borderRadius: 2,
          overflow: 'hidden',
        },
      }}
      fullWidth
      onClose={onClose}
    >
      <DialogTitle
        sx={{
          borderBottom: `1px solid ${theme.palette.divider}`,
          pb: 2,
          pt: 3,
          px: 3,
        }}
      >
        <Typography fontWeight="500" variant="h5">
          {t('chat:botSelector.newBotForm.title')}
        </Typography>
        <Typography color="text.secondary" sx={{ mt: 0.5 }} variant="body2">
          {t('chat:botSelector.newBotForm.description')}
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ p: 3, pt: 2 }}>
        <Tabs
          sx={{ mb: 3 }}
          value={tabValue}
          variant="fullWidth"
          onChange={handleTabChange}
        >
          <Tab label={t('chat:botSelector.newBotForm.myOwnBot')} />
          <Tab label={t('chat:botSelector.newBotForm.zetaAlphaAgent')} />
          <Tab label={t('chat:botSelector.newBotForm.agentsSDK')} />
        </Tabs>

        {/* Case 1: My own bot */}
        {tabValue === 0 && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
            <TextField
              label={t('chat:botSelector.newBotForm.ownBot.name')}
              placeholder={t('chat:botSelector.newBotForm.ownBot.nameExample')}
              value={botName}
              fullWidth
              required
              onChange={(e) => setBotName(e.target.value)}
            />

            <TextField
              label={t('chat:botSelector.newBotForm.ownBot.description')}
              placeholder={t(
                'chat:botSelector.newBotForm.ownBot.descriptionExample'
              )}
              value={botDescription}
              fullWidth
              onChange={(e) => setBotDescription(e.target.value)}
            />

            <TextField
              helperText={t(
                'chat:botSelector.newBotForm.ownBot.instructionsHelp'
              )}
              label={t('chat:botSelector.newBotForm.ownBot.instructions')}
              placeholder={t(
                'chat:botSelector.newBotForm.ownBot.instructionsExample'
              )}
              rows={4}
              value={instructions}
              fullWidth
              multiline
              onChange={(e) => setInstructions(e.target.value)}
            />
          </Box>
        )}

        {/* Case 2: Zeta Alpha agent */}
        {tabValue === 1 && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
            <TextField
              label={t('chat:botSelector.newBotForm.ZAAgent.name')}
              placeholder={t('chat:botSelector.newBotForm.ZAAgent.nameExample')}
              value={zaBotName}
              fullWidth
              required
              onChange={(e) => setZaBotName(e.target.value)}
            />
            <Typography variant="subtitle1">
              {t('chat:botSelector.newBotForm.ZAAgent.selectSources')}
            </Typography>

            <FormGroup>
              {['My Data', 'All the Web', 'All of Science'].map((source) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={selectedSources.includes(source)}
                      onChange={() => handleSourceToggle(source)}
                    />
                  }
                  key={source}
                  label={source}
                />
              ))}
            </FormGroup>

            <TextField
              helperText={t(
                'chat:botSelector.newBotForm.ZAAgent.instructionsHelp'
              )}
              label={t('chat:botSelector.newBotForm.ZAAgent.instructions')}
              placeholder={t(
                'chat:botSelector.newBotForm.ZAAgent.instructionsExample'
              )}
              rows={4}
              value={zaInstructions}
              fullWidth
              multiline
              onChange={(e) => setZaInstructions(e.target.value)}
            />
          </Box>
        )}

        {/* Case 3: Agents SDK */}
        {tabValue === 2 && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
            <TextField
              label={t('chat:botSelector.newBotForm.agentSDK.agentIdentifier')}
              placeholder={t(
                'chat:botSelector.newBotForm.agentSDK.agentIdentifierExample'
              )}
              value={agentIdentifier}
              fullWidth
              required
              onChange={(e) => setAgentIdentifier(e.target.value)}
            />
            <TextField
              label={t('chat:botSelector.newBotForm.agentSDK.name')}
              placeholder={t(
                'chat:botSelector.newBotForm.agentSDK.nameExample'
              )}
              value={sdkBotName}
              fullWidth
              required
              onChange={(e) => setSdkBotName(e.target.value)}
            />
            <TextField
              label={t('chat:botSelector.newBotForm.agentSDK.description')}
              placeholder={t(
                'chat:botSelector.newBotForm.agentSDK.descriptionExample'
              )}
              value={sdkBotDescription}
              fullWidth
              onChange={(e) => setSdkBotDescription(e.target.value)}
            />
          </Box>
        )}
      </DialogContent>

      <DialogActions
        sx={{ borderTop: `1px solid ${theme.palette.divider}`, px: 3, py: 2 }}
      >
        <Button
          sx={{
            borderRadius: 1.5,
            fontWeight: 500,
            minWidth: 100,
            textTransform: 'none',
          }}
          onClick={onClose}
        >
          {t('chat:botSelector.cancel')}
        </Button>
        <Button
          disabled={
            (tabValue === 0 && !botName.trim()) ||
            (tabValue === 1 && !zaBotName.trim()) ||
            (tabValue === 2 && (!sdkBotName.trim() || !agentIdentifier.trim()))
          }
          sx={{
            borderRadius: 1.5,
            fontWeight: 500,
            minWidth: 100,
            textTransform: 'none',
          }}
          variant="contained"
          onClick={handleSubmit}
        >
          {t('chat:botSelector.create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
