import React, { useCallback, useEffect, useRef, useState } from 'react';

import AddCommentIcon from '@mui/icons-material/AddComment';
import { Box, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ChatMessageSenderEnum } from '@zarn/vendor/dist/search';

import { BotConfiguration } from 'api/tenantSettingsApi/tenantSettingsApi.types';
import TrackedIconButton from 'common/components/Buttons/TrackedIconButton/TrackedIconButton';
import { BoxWithoutScroll } from 'common/components/WithoutScroll/WithoutScroll';
import { Undefined } from 'common/utils/assert';
import { selectUser } from 'containers/User/user.slice';

import { BotTypeSelector } from './BotTypeSelector/BotTypeSelector';
import { ChatProps } from './Chat.props';
import { ConversationPayload } from './Chat.types';
import { ConversationList } from './Conversation/ConversationList';
import { Greetings } from './Greetings/Greetings';
import { isPayloadWithHits } from './hooks/helpers';
import { useChat } from './hooks/useChat';
import { useChatMessages } from './hooks/useChatMessages';
import { useChatPage } from './hooks/useChatPage';
import { useChatScrolling } from './hooks/useChatScrolling';
import { LoadingMessage } from './LoadingMessage/LoadingMessage';
import { Message } from './Message';
import { MessageInput } from './MessageInput';
import { ModeButtons } from './ModeButtons';
import { SavingStatus } from './SavingStatus/SavingStatus';

export const Chat = <T extends ConversationPayload>({
  content,
  disabled,
  isEvidenceDialog,
  isLoading,
  isSaving,
  onEvidenceChunkClick,
  onEvidenceItemClick,
  onSave,
  options,
  predefinedConversation,
  renderContextAction,
}: ChatProps<T>) => {
  const {
    botTypesConfiguration,
    chatConfig,
    context,
    greetings,
    header,
    payload,
    placeholder,
  } = options;

  const { t } = useTranslation('common');
  const { palette } = useTheme<Theme>();

  const chatMessagesRef = useRef<HTMLDivElement | null>(null);

  const { isChatPage } = useChatPage();
  const [selectedBot, setSelectedBot] = useState<Undefined<BotConfiguration>>(
    botTypesConfiguration?.[predefinedConversation?.botType ?? ''] ??
      botTypesConfiguration?.[chatConfig?.defaultBotType ?? '']
  );

  const { buildDefaultMessage } = useChatMessages(selectedBot?.tenantBotType);
  const user = useSelector(selectUser);

  const {
    conversation,
    error,
    isSending,
    loadingMessage,
    onReset,
    onSendMessage,
  } = useChat({
    content,
    context,
    onSave,
    payload,
    predefinedConversation,
  });

  const isError = !!error;

  const { enableScrolling, onScroll, scrollToBottom, scrollToBottomSlowly } =
    useChatScrolling(chatMessagesRef);

  useEffect(() => {
    if (chatMessagesRef.current) {
      scrollToBottom(chatMessagesRef.current.scrollHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation, isLoading]);

  useEffect(() => {
    scrollToBottomSlowly();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingMessage]);

  const handleSubmit = useCallback(
    (message: string, documentsIds: string[]) => {
      enableScrolling();
      return onSendMessage(
        buildDefaultMessage(message, {
          botParams: selectedBot?.botParams,
          defaultBotType: selectedBot?.tenantBotType,
          documentsIds,
        })
      );
    },
    [
      enableScrolling,
      onSendMessage,
      buildDefaultMessage,
      selectedBot?.botParams,
      selectedBot?.tenantBotType,
    ]
  );

  const history = useHistory();

  const isCurrentPageDiscover = history.location.pathname === '/';
  const isCurrentPageChat = history.location.pathname === '/chat';

  const isNewChatDisabled =
    isSending ||
    isLoading ||
    !conversation ||
    !conversation.messages.length ||
    isCurrentPageDiscover;

  const isChatDisabled =
    disabled ||
    isSending ||
    isLoading ||
    isSaving ||
    isLoading ||
    !selectedBot?.tenantBotType;

  const supportedBotTypes = chatConfig?.extraBotTypes ?? [];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      {header}
      <BoxWithoutScroll
        ref={chatMessagesRef}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          mt: 2,
          overflow: 'hidden scroll',
        }}
        onScroll={onScroll}
      >
        {!conversation?.messages.length && !isSaving && !loadingMessage && (
          <Greetings greetings={greetings} isLoading={isLoading} />
        )}
        {isChatPage
          ? !conversation?.messages.length &&
            chatConfig?.extraBotTypes &&
            chatConfig?.extraBotTypes.length > 0 && (
              <BotTypeSelector
                botIdentifierToConfig={botTypesConfiguration}
                chatConfig={chatConfig}
                disabled={disabled}
                initiallySelectedBotType={selectedBot?.tenantBotType}
                onSelectBot={(bot) => {
                  setSelectedBot(bot);
                }}
              />
            )
          : !conversation && (
              <ModeButtons
                disabled={disabled}
                supportedBotTypes={supportedBotTypes}
                onClick={onSendMessage}
              />
            )}
        {/*TODO: create new component */}
        {error && (
          <Box sx={{ display: 'flex', justifyContent: 'center', m: 2 }}>
            <Typography variant="body2">
              This chat is not available. It either has been removed or does not
              exist.
            </Typography>
          </Box>
        )}
        {!error && conversation && !!conversation.messages.length && (
          <>
            {
              // @ts-ignore
              renderContextAction?.(conversation)
            }

            <ConversationList
              hits={
                conversation.payload && isPayloadWithHits(conversation.payload)
                  ? conversation.payload.hits
                  : undefined
              }
              initials={user?.initials}
              isEvidenceDialog={isEvidenceDialog}
              messages={conversation.messages}
              onEvidenceChunkClick={onEvidenceChunkClick}
              onEvidenceItemClick={onEvidenceItemClick}
            >
              <SavingStatus isSaving={isSaving} />
            </ConversationList>
          </>
        )}
        {isError && (
          <Message
            isContent={true}
            sender={ChatMessageSenderEnum.Bot}
            sx={{ backgroundColor: palette.error.light }}
          >
            <Tooltip title={error?.message}>{t('chat.message.error')}</Tooltip>
          </Message>
        )}
        {(isSending || loadingMessage) && (
          <LoadingMessage message={loadingMessage} />
        )}
      </BoxWithoutScroll>

      <Box>
        <Box
          style={{
            alignItems: 'center',
            display: 'flex',
            gap: '0.25rem',
            padding: '0rem 0.5rem 1rem 0.5rem',
          }}
        >
          {isCurrentPageChat ? null : (
            <Tooltip title={t('chat.moreMenu.start')}>
              <Box>
                <TrackedIconButton
                  data-testid="new-chat-button"
                  disabled={isNewChatDisabled}
                  onClick={onReset}
                >
                  <AddCommentIcon />
                </TrackedIconButton>
              </Box>
            </Tooltip>
          )}
          <MessageInput
            disabled={isChatDisabled}
            isLoading={isSaving || isLoading}
            placeholder={
              placeholder ??
              (!conversation?.messages.length
                ? t('chat.message.input.firstMessagePlaceholder')
                : 'How can I help?')
            }
            onSubmit={handleSubmit}
          />
        </Box>
      </Box>
    </Box>
  );
};
