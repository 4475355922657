import React, { ElementType, FC, ReactNode } from 'react';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Box, Tooltip, Typography } from '@mui/material';
import { BoxProps } from '@mui/material/Box/Box';

import { StyledBadge } from 'common/components/Badges/StyledBadge';

import { useStyles } from './styles';

interface AttachmentProps extends BoxProps {
  Icon?: ElementType;
  button?: ReactNode;
  title: string;
  tooltip?: string;
}

export const Attachment: FC<AttachmentProps> = ({
  button,
  Icon = InsertDriveFileIcon,
  title,
  tooltip,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.attachmentBox} {...props}>
      <StyledBadge
        badgeContent={button}
        className={classes.badge}
        overlap="circular"
      >
        <Icon color="action" />
        <Tooltip title={tooltip ?? title}>
          <Typography variant="caption">{title}</Typography>
        </Tooltip>
      </StyledBadge>
    </Box>
  );
};
