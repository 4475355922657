import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    display: 'inline-flex',
    minWidth: 26,
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  textActive: {
    fontWeight: 'bold',
  },
  toggleButton: {
    ...theme.typography.body2,
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    display: 'flex',
    height: 28,
    overflow: 'hidden',
    padding: 0,
    width: '100%',
  },
  toggleIcon: {
    backgroundColor: 'transparent',
    color: theme.palette.text.secondary,
    display: 'flex',
    margin: 0,
  },
}));
