import { useCallback, useEffect, useRef, useState } from 'react';

interface Props {
  minified?: boolean;
}
export const useChatNav = ({ minified }: Props) => {
  const itemRef = useRef<HTMLDivElement | null>(null);

  const getMaxHeight = useCallback(() => {
    const { top } = itemRef.current?.getBoundingClientRect() ?? { top: 500 };
    return window.innerHeight - top - 20;
  }, []);

  const [popoverMaxHeight, setPopoverMaxHeight] = useState<number>(
    getMaxHeight()
  );

  const [open, setOpen] = useState(!minified);

  const handleClickAway = () => {
    if (minified) {
      setOpen(false);
    }
  };

  useEffect(() => {
    const resizeCb = () => {
      setPopoverMaxHeight(getMaxHeight());
    };

    window.addEventListener('resize', resizeCb);

    setTimeout(() => {
      resizeCb();
    }, 1000);

    return () => {
      window.removeEventListener('resize', resizeCb);
    };
  }, [getMaxHeight, itemRef, setPopoverMaxHeight]);

  useEffect(() => {
    setOpen(!minified);
  }, [minified]);

  const handleClose = () => {
    setOpen(false);
  };

  return {
    handleClickAway,
    handleClose,
    itemRef,
    open,
    popoverMaxHeight,
    setOpen,
  };
};
