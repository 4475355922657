import React, { useContext } from 'react';

import { Drawer, Hidden, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AppTheme } from 'app/theme';
import clsx from 'clsx';

import { DrawerResize } from 'common/components/DrawerResize/DrawerResize';
import { TrackedActionContext } from 'common/components/TrackedActions/trackedAction.context';
import { PageContext } from 'containers/Page/Page.context';

import { MainSidebarSignInButton } from './MainSidebarSignInButton/MainSidebarSignInButton';
import { SidebarContent } from './SidebarContent/SidebarContent';
import { useMainSidebarResize } from './useMainSidebarResize';

const useStyles = makeStyles<AppTheme>((theme) => ({
  appDrawer: {
    zIndex: 10,
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      top: 64,
    },
  },
  drawerMinified: {
    overflow: 'visible',
    width: 70,
  },
  drawerPaper: {
    background: theme.palette.sideDrawer.background,
    backgroundColor: theme.palette.sideDrawer.backgroundColor,
    overflowY: 'auto',
    padding: theme.spacing(1, 0),
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh - 64px)',
      maxWidth: '30vw',
      position: 'relative',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '20vw',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '85vw',
      width: 400,
    },
  },
}));

export const MainSidebar = () => {
  const { pageDrawerMinified, pageDrawerMobileOpen, setPageDrawerMobileOpen } =
    useContext(PageContext);
  const { onResizeStart, width } = useMainSidebarResize();
  const theme = useTheme<AppTheme>();

  const classes = useStyles({ theme });

  const handleDrawerToggle = () => {
    setPageDrawerMobileOpen(!pageDrawerMobileOpen);
  };

  return (
    <TrackedActionContext.Provider value={{ actionContext: 'Sidebar menu' }}>
      <nav
        aria-label="App sidebar"
        className={classes.appDrawer}
        data-testid="MainSidebar"
      >
        <Hidden mdUp>
          <Drawer
            classes={{ paper: classes.drawerPaper }}
            ModalProps={{ keepMounted: true }}
            open={pageDrawerMobileOpen}
            variant="temporary"
            onClose={handleDrawerToggle}
          >
            <MainSidebarSignInButton />
            <SidebarContent />
          </Drawer>
        </Hidden>

        <Hidden mdDown>
          <Drawer
            classes={{
              paper: clsx(
                classes.drawerPaper,
                pageDrawerMinified && classes.drawerMinified
              ),
            }}
            PaperProps={{ style: !pageDrawerMinified ? { width } : {} }}
            variant="permanent"
            open
          >
            {!pageDrawerMinified && (
              <DrawerResize onResizeStart={onResizeStart} />
            )}

            <SidebarContent minified={pageDrawerMinified} />
          </Drawer>
        </Hidden>
      </nav>
    </TrackedActionContext.Provider>
  );
};
