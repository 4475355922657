import React from 'react';

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useTheme,
} from '@mui/material';
import { AppTheme } from 'app/theme';
import clsx from 'clsx';
import { LocationDescriptor } from 'history';
import { LocationState } from 'pages/routes.config';
import { NavLink } from 'react-router-dom';

import { useIsActivePage } from '../../../hooks/useIsActivePage';

import { useStyles } from './useStyles';

export interface PageDrawerMenuItemProps {
  children?: React.ReactNode;
  className?: string;
  exact?: boolean;
  icon?: React.ReactNode;
  isDisabled?: boolean;
  minified?: boolean;
  title: React.ReactNode;
  to: LocationDescriptor<LocationState>;
  tooltip?: string;
}

export const PageDrawerMenuItemComponent = React.forwardRef(
  (
    {
      children,
      className,
      exact,
      icon,
      isDisabled,
      minified,
      title,
      to,
      tooltip = '',
    }: PageDrawerMenuItemProps,
    ref?: React.Ref<HTMLDivElement>
  ) => {
    const {
      button,
      disabledDiv,
      listItemContainer,
      navLink,
      navLinkSelected,
      secondaryAction,
      tooltipPopper,
    } = useStyles();

    const theme = useTheme<AppTheme>();

    const { isActive } = useIsActivePage(to as string);

    return (
      <ListItem
        classes={{
          button,
          container: listItemContainer,
          secondaryAction,
        }}
        className={clsx(className, isActive ? navLinkSelected : undefined)}
        data-testid="PageDrawerMenuItem"
        ref={ref}
        role="menuitem"
        button
      >
        <Tooltip
          classes={{ popper: clsx(!minified && tooltipPopper) }}
          placement="right"
          title={tooltip}
        >
          <NavLink
            className={isDisabled ? disabledDiv : navLink}
            exact={exact}
            to={to}
          >
            {icon && (
              <ListItemIcon
                sx={{
                  color: isActive
                    ? theme.palette.sideDrawer.icons.highlight
                    : theme.palette.sideDrawer.icons.defaultColor,
                }}
              >
                {icon}
              </ListItemIcon>
            )}
            {!minified && (
              <ListItemText
                primary={title}
                primaryTypographyProps={{ noWrap: true, variant: 'body2' }}
                disableTypography
              />
            )}
          </NavLink>
        </Tooltip>
        {children}
      </ListItem>
    );
  }
);

PageDrawerMenuItemComponent.displayName = 'PageDrawerMenuItemComponent';

export const PageDrawerMenuItem = React.memo(PageDrawerMenuItemComponent);
