const STORAGE_KEY = 'lastOpenedChatId';

export const useLastOpenedChat = () => {
  const getLastOpenedChatId = () => localStorage.getItem(STORAGE_KEY);

  const setLastOpenedChatId = (id: string) => {
    localStorage.setItem(STORAGE_KEY, id);
  };

  const removeLastOpenedChatId = () => {
    localStorage.removeItem(STORAGE_KEY);
  };

  return {
    getLastOpenedChatId,
    removeLastOpenedChatId,
    setLastOpenedChatId,
  };
};
