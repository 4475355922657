import React, { Fragment, useCallback } from 'react';

import { Divider } from '@mui/material';
import { captureException } from '@sentry/react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import {
  NoteDetails,
  NoteListSortBy,
  NoteObjectId,
} from 'api/notesApi/notesApi.types';
import { NoteItem } from 'common/components/Notes/NoteItem';
import { NoteItemSkeleton } from 'common/components/Notes/NoteItemSkeleton';
import { deserializeAxiosError } from 'common/utils/error';
import { useParsedHostname } from 'common/utils/useParsedHostname';

import { useDocNoteDelete } from '../hooks/useDocNoteDelete';
import { useDocNotes } from '../hooks/useDocNotes';
import { useDocNoteUpdate } from '../hooks/useDocNoteUpdate';

export type DocNotesResultsProps = {
  docId: string;
  organizeDocId: string;
  q?: string;
  sortBy?: NoteListSortBy;
  withAnnotations?: boolean;
};

const DocNotesResults = ({
  docId,
  organizeDocId,
  q,
  sortBy,
  withAnnotations,
}: DocNotesResultsProps) => {
  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const parsedHostname = useParsedHostname();

  const { data, isLoading } = useDocNotes({
    docId: organizeDocId,
    q,
    sortBy,
    withAnnotations,
  });
  const { mutateAsync: deleteDocNote } = useDocNoteDelete({
    docId: organizeDocId,
    q,
    sortBy,
  });
  const { mutateAsync: updateDocNote } = useDocNoteUpdate({
    docId: organizeDocId,
    q,
    sortBy,
  });

  const handleDelete = useCallback(
    async (_: NoteObjectId, noteId: number) => {
      try {
        await deleteDocNote(noteId);
        enqueueSnackbar(t('notes.delete.successMessage'));
      } catch (error) {
        captureException(error);
        enqueueSnackbar(deserializeAxiosError(error).message, {
          variant: 'error',
        });
      }
    },
    [deleteDocNote, enqueueSnackbar, t]
  );

  const handleUpdate = useCallback(
    async (note: NoteDetails) => {
      try {
        await updateDocNote({
          annotationHighlight: note.annotationHighlight,
          content: note.content,
          noteId: note.id,
          objectId: organizeDocId,
          objectType: 'document',
          sharing: note.sharing,
          tenant: parsedHostname.tenant,
        });
        enqueueSnackbar(t('notes.update.successMessage'));
      } catch (error) {
        captureException(error);
        enqueueSnackbar(deserializeAxiosError(error).message, {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, organizeDocId, parsedHostname.tenant, t, updateDocNote]
  );

  return (
    <div>
      {isLoading && <NoteItemSkeleton />}

      {data?.items.map((note) => (
        <Fragment key={note.id}>
          <Divider />

          <NoteItem
            docId={docId}
            note={note}
            onDelete={handleDelete}
            onUpdate={handleUpdate}
          />
        </Fragment>
      ))}
    </div>
  );
};

DocNotesResults.displayName = 'DocNotesResults';

export default DocNotesResults;
