import { Optional } from 'common/utils/assert';
import { useChunkDetails } from 'containers/Chat/DocsContext/hooks/useChunkDetails';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

import { hasPdf, isExternalDoc } from './helpers';

export const useDocumentLink = (
  href: string,
  docId: Optional<string>,
  docData: Optional<RetrievalUnitData>,
  chunkId: Optional<string>,
  isPdfOpen: boolean
) => {
  const { data: chunksData } = useChunkDetails({
    chunkId: chunkId ?? null,
    options: {
      enabled: !!chunkId,
    },
  });
  const chunkData = chunksData?.[0];

  if (isPdfOpen) {
    return '';
  }

  if (isExternalDoc(docData) && docData?.uri) {
    return docData.uri;
  }

  if (docId && chunkId && hasPdf(docData)) {
    return `/pdf/${docId}${chunkData ? `?chunkId=${chunkId}` : ''}`;
  }

  if (href.startsWith('/documents/')) {
    return `/documents/${docId}`;
  }

  return href;
};
