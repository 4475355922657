import { AxiosResponse } from 'axios';

import { Configuration, TenantSettingsApi } from '@zarn/vendor/dist/search';

import { BASE_HEADERS, SERVICE_URL } from 'api/apiConfig';

import AxiosInstance from '../axiosInstance';

import { TenantSettings } from './tenantSettingsApi.types';
import { deserializeTenantSettings } from './tenantSettingsApi.utils';

const tenantSettingsApi = new TenantSettingsApi(
  new Configuration({ baseOptions: BASE_HEADERS, basePath: SERVICE_URL }),
  SERVICE_URL,
  AxiosInstance
);

export const retrieveTenantSettings = async (
  tenant: string,
  indexCluster: string | null
): Promise<AxiosResponse<TenantSettings>> => {
  const { data, ...response } = await tenantSettingsApi.retrieveTenantSettings({
    tenant,
  });

  return {
    ...response,
    data: deserializeTenantSettings(data, indexCluster),
  };
};
