import { useCallback, useState } from 'react';

import { captureException } from '@sentry/react';

import {
  type ChatMessage,
  type ChatResponseForm as ChatResponseFormChat,
} from '@zarn/vendor/dist/chat-service';
import type { GenericError } from '@zarn/vendor/dist/saved-results';
import type { ChatResponseForm as ChatResponseFormSearch } from '@zarn/vendor/dist/search';

import { BASE_API_URL } from 'api/apiConfig';
import { requestChatAnswer } from 'api/chatApi/chatApi';
import type {
  ChatContext,
  ChatMessageElement,
} from 'api/chatApi/chatApi.types';
import { serializeChatSearchPayload } from 'api/chatApi/chatApi.utils';
import {
  deserializeChatStreamMessage,
  serializeChatStreamPayload,
} from 'api/chatApi/chatStreamingApi.utils';
import { sseRequest } from 'api/sse';
import { isFeatureEnabled } from 'common/components/FeatureFlags/Feature';
import { TENANT } from 'common/constants';
import { deserializeAxiosError } from 'common/utils/error';
import {
  parseHostname,
  useParsedHostname,
} from 'common/utils/useParsedHostname';

import type { Conversation, ConversationPayload } from '../Chat.types';

export const sendSseRequest = async <T extends ConversationPayload>(
  payload: ChatResponseFormChat,
  onMessage: (message: ChatMessageElement | null) => void,
  conversation: Conversation<T>
) => {
  const { tenant } = parseHostname();

  return new Promise<Conversation<T>>((resolve, reject) => {
    let finalMessage: ChatMessageElement | null = null;

    const getDocumentContext = () => {
      // @ts-ignore
      if (!payload?.context?.from_document_ids) {
        return undefined;
      }
      // @ts-ignore
      return { ...payload.context.from_document_ids };
    };

    const getCustomContext = () => {
      // @ts-ignore
      if (!payload?.context?.custom_context) {
        return undefined;
      }
      // @ts-ignore
      return { ...payload.context.custom_context };
    };

    const documentContext = getDocumentContext();
    const customContext = getCustomContext();

    const ssePayload = {
      // TODO: Check what is here
      // @ts-ignore
      agent_identifier: payload.bot_type,
      bot_params: payload.bot_params,
      conversation: payload.conversation,
      conversation_context: {
        custom_context: customContext,
        document_context: documentContext,
      },
    };

    (async () => {
      try {
        await sseRequest({
          onError: (err: Error) => {
            reject(err);
            finalMessage = null;
          },

          onFinish: async () => {
            resolve({
              ...conversation,
              messages: finalMessage
                ? [...conversation.messages, finalMessage]
                : conversation.messages,
            });
            finalMessage = null;
          },

          onMessage: (message: ChatMessage) => {
            finalMessage = deserializeChatStreamMessage(message);
            onMessage(finalMessage);
          },

          payload: ssePayload,
          url: `${BASE_API_URL}/service/chat/stream?tenant=${tenant ?? TENANT}`,
        });
      } catch (error) {
        reject(error);
      }
    })();
  });
};

const sendApiRequest = async <T extends ConversationPayload>(
  payload: ChatResponseFormSearch,
  tenant: string,
  conversation: Conversation<T>
): Promise<Conversation<T>> => {
  const response = await requestChatAnswer(payload, tenant);

  return {
    ...conversation,
    messages: response.data.conversation,
  };
};

interface Props {
  botParams?: Record<string, any>;
  context: ChatContext;
}

export const useChatSending = <T extends ConversationPayload = {}>({
  botParams,
  context,
}: Props) => {
  const { tenant } = useParsedHostname();
  const isSseAvailable = isFeatureEnabled('ff-chat-streaming');

  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState<GenericError | null>(null);
  const [loadingMessage, setLoadingMessage] =
    useState<ChatMessageElement | null>(null);

  const send = useCallback(
    async (conversation: Conversation<T>) => {
      try {
        setError(null);
        setIsSending(true);
        setLoadingMessage(null);

        let responseConversation: Conversation<T>;

        if (isSseAvailable) {
          const payload = serializeChatStreamPayload({
            chatResponseForm: {
              botParams: botParams ?? conversation.botParams,
              botType: conversation.botType,
              context,
              conversation: conversation.messages,
            },
          });

          responseConversation = await sendSseRequest(
            payload,
            setLoadingMessage,
            conversation
          );

          setLoadingMessage(null);
        } else {
          const payload = serializeChatSearchPayload({
            chatResponseForm: {
              botParams: botParams ?? conversation.botParams,
              botType: conversation.botType,
              context,
              conversation: conversation.messages,
            },
          });
          responseConversation = await sendApiRequest(
            payload,
            tenant,
            conversation
          );
        }

        setIsSending(false);
        return responseConversation;
      } catch (err) {
        setError(err as Error);
        setLoadingMessage(null);
        return Promise.reject(err);
      } finally {
        setIsSending(false);
      }
    },
    [context, botParams, isSseAvailable, tenant]
  );

  const sendConversation = useCallback(
    async (conversation: Conversation<T>) => {
      try {
        setIsSending(true);
        setError(null);

        return await send(conversation);
      } catch (err) {
        captureException(err);
        setError(deserializeAxiosError(err));
      } finally {
        setIsSending(false);
      }
      return null;
    },
    [send]
  );

  return { error, isSending, loadingMessage, sendConversation };
};
