import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    '&:hover': {
      backgroundColor: theme.palette.grey[600],
      color: theme.palette.common.white,
    },
    padding: 0,
    transition: 'background-color 0.1s',
  },
}));
