export const uuid = (): string => self.crypto.randomUUID();

export const makeHash = async (data: string): Promise<string> => {
  const msgUint8 = new TextEncoder().encode(data);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
};

export const encrypt = (content: string) =>
  window.btoa(unescape(encodeURIComponent(content)));

export const decrypt = (content: string) =>
  decodeURIComponent(escape(window.atob(content)));
