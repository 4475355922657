import { RetrievalUnitEnum } from 'common/enums';
import { PartialRecord } from 'common/utils/types.helpers';

import { WhitelabelSettings } from './WhitelabelSettings.types';

export interface SearchFilters {
  codeFilterEnabled: boolean;
  countryFilterEnabled: boolean;
  dateFilterEnabled: boolean;
  organizationFilterEnabled: boolean;
  ownerFilterEnabled: boolean;
  sourceFilterEnabled: boolean;
  tagFilterEnabled: boolean;
  typeFilterEnabled: boolean;
}

export type WidgetName =
  | 'QA'
  | 'expertSearch'
  | 'queryAnalysis'
  | 'vosViewer'
  | 'analytics'
  | 'searchResults'
  | 'findSimilarDocument'
  | 'findAuthoredBy';

export interface LayoutColumnSettings {
  sizes?: {
    lg?: number;
    md?: number;
    sm?: number;
    xl?: number;
    xs?: number;
    xxl?: number;
  };
  widgets: WidgetName[];
}

export interface WidgetLayoutSettings {
  columns: LayoutColumnSettings[];
  topFullWidthWidgets: WidgetName[];
}

export interface Widgets {
  analyticsWidgetEnabled: boolean;
  expertSearchWidgetEnabled: boolean;
  findAuthoredByWidgetEnabled: boolean;
  findSimilarDocumentWidgetEnabled: boolean;
  layout: WidgetLayoutSettings;
  qaWidgetEnabled: boolean;
  queryAnalysisWidgetEnabled: boolean;
  searchResultsWidgetEnabled: boolean;
  vosViewerWidgetEnabled: boolean;
}

export interface Tab {
  enabled: boolean;
  tabTitle: string;
}

export interface Tabs {
  default: Tab;
  google: Tab;
  googleScholar: Tab;
}

export interface OidcEnabled {
  authority: string;
  clientId: string;
  enabled: true;
}

export interface OidcDisabled {
  authority?: string;
  clientId?: string;
  enabled: false;
}

export type Oidc = OidcDisabled | OidcEnabled;

export type BotIdentifier = string;
export type BotType = string;

export interface BotConfiguration {
  botParams?: Record<string, unknown>;
  description?: string;
  icon?: string;
  identifier: BotIdentifier;
  label?: string;
  tenantBotType: BotType;
}

export interface ChatConfig {
  defaultBotType?: BotType;
  extraBotTypes?: BotType[];
}

export interface ChatSettings {
  botTypesConfiguration: PartialRecord<string, BotConfiguration>;
  chatPageConfig?: ChatConfig;
  pdfViewerPageConfig?: ChatConfig;
  qaWidgetConfig?: ChatConfig;
  tagPageConfig?: ChatConfig;
}

export enum SearchFilterDynamicsType {
  Checkbox,
  // TODO: Implement it
  DatesRange,
}

export interface DynamicSettingsAbstract {
  faceted: boolean;
  fieldName: string;
  filterType: SearchFilterDynamicsType;
  filterTypeSettings: never;
  label: string;
  urlParam: string;
}

export interface CheckboxDynamicSettings
  extends Omit<DynamicSettingsAbstract, 'filterTypeSettings'> {
  filterType: SearchFilterDynamicsType.Checkbox;
  filterTypeSettings: {
    values: Array<{
      label: string;
      value: string;
    }>;
  };
}

// TODO: Implement it
export interface DatesRangeDynamicSettings
  extends Omit<DynamicSettingsAbstract, 'filterTypeSettings'> {
  filterType: SearchFilterDynamicsType.DatesRange;
  filterTypeSettings: {
    max: string;
    min: string;
  };
}

export type DynamicSettings =
  | CheckboxDynamicSettings
  | DatesRangeDynamicSettings;

/** @deprecated */
export interface ApiDocumentMetadataFields {
  field_name?: string;
  icon?: string;
  label?: string;
  list_field_name?: string;
  type?: string;
  url_field_name?: string;
}

/** @deprecated */
export interface ApiDisplayConfiguration {
  bounding_boxes_field?: string;
  created_by_field?: string;
  date_field?: string;
  description_field?: string;
  document_metadata_fields?: ApiDocumentMetadataFields[];
  image_url_field?: string;
  source_field?: string;
  title_field?: string;
  url_field?: string;
}

export interface DocumentMetadataFields {
  fieldName?: string;
  icon?: string;
  label?: string;
  listFieldName?: string;
  type?: string;
  urlFieldName?: string;
}

export interface DisplayConfiguration {
  boundingBoxesField?: string;
  createdByField?: string;
  dateField?: string;
  descriptionField?: string;
  documentMetadataFields?: DocumentMetadataFields[];
  imageUrlField?: string;
  sourceField?: string;
  titleField?: string;
  urlField?: string;
}

export interface FeaturesSettings {
  documentUploads?: { status: 'active' | 'disabled' | 'hidden' };
  people?: { status: 'active' | 'disabled' | 'hidden' };
  recommendations?: { status: 'active' | 'disabled' | 'hidden' };
}

export interface SortingOptionsSettings {
  title: string;
  urlParam?: string;
  value: string;
}

export interface SearchSortingSettings {
  chunk?: SortingOptionsSettings[];
  document?: SortingOptionsSettings[];
}

export interface SearchFiltersInitialValues {
  retrievalUnit: RetrievalUnitEnum;
}

export interface TenantSettings {
  chat: ChatSettings;
  displayConfiguration?: DisplayConfiguration | null;
  document: {
    exports?: string[];
    sharing?: string[];
  };
  features: FeaturesSettings;
  indexCluster: string;
  note: {
    exports?: string[];
    sharing?: string[];
  };
  oidc: Oidc;
  searchFilters: SearchFilters;
  searchFiltersDynamic?: DynamicSettings[];
  searchFiltersInitialValues?: SearchFiltersInitialValues;
  searchSorting?: SearchSortingSettings;
  tabs: Tabs;
  tag: {
    exports?: string[];
    sharing?: string[];
  };
  whitelabel?: WhitelabelSettings;
  widgets: Widgets;
}

export interface BotTypeMessageWithoutMessage {
  botType: string;
}

export interface BotTypeMessageWithMessage
  extends BotTypeMessageWithoutMessage {
  botParams?: Record<string, any>;
  documentsIds?: string[];
  image?: string | null;
  message: string;
}

export interface BotTypeMessageWithOptionalMessage
  extends BotTypeMessageWithoutMessage {
  botParams?: Record<string, any>;
  documentsIds?: string[];
  image?: string | null;
  message?: string;
}

export type DefaultBotTypeMessage = BotTypeMessageWithMessage;
export type SummaryBotTypeMessage = BotTypeMessageWithMessage;
export type QuizbotBotTypeMessage = BotTypeMessageWithoutMessage;
export type TagOnboardingBotTypeMessage = BotTypeMessageWithOptionalMessage;

export type BotTypeMessage =
  | DefaultBotTypeMessage
  | SummaryBotTypeMessage
  | QuizbotBotTypeMessage
  | TagOnboardingBotTypeMessage;
