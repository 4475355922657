import React, { FC, ReactNode } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton, Toolbar, useTheme } from '@mui/material';
import { AppTheme } from 'app/theme';

import { NavbarProfile } from 'containers/NavbarProfile';
import { Notifications } from 'containers/Notifications/Notifications';

import { MainToolbarButtons } from './MainToolbarAuthButtons/MainToolbarButtons';
import { MainToolbarLogo } from './MainToolbarLogo';
import MainToolbarPrivateDocs from './MainToolbarPrivateDocs/MainToolbarPrivateDocs';
import { useStyles } from './styles';

interface Props {
  children?: ReactNode;
  handleDrawerToggle: () => void;
  isAuthenticated: boolean;
  isDocumentUploadsActive: boolean;
  isMobile: boolean;
}

export const DefaultToolbar: FC<Props> = ({
  children,
  handleDrawerToggle,
  isAuthenticated,
  isDocumentUploadsActive,
  isMobile,
}) => {
  const classes = useStyles();
  const theme = useTheme<AppTheme>();

  return (
    <Toolbar>
      <IconButton
        aria-label="open drawer"
        className={classes.menuButton}
        color="contrast"
        edge={isMobile && 'start'}
        size="large"
        onClick={handleDrawerToggle}
      >
        <MenuIcon sx={{ color: theme.palette.header.iconColor }} />
      </IconButton>

      <MainToolbarLogo />

      {children}

      <div className={classes.spacing} />

      <Box alignItems="center" display="flex" gap={1}>
        <Notifications />
        <MainToolbarButtons />
        {isAuthenticated && isDocumentUploadsActive && (
          <MainToolbarPrivateDocs />
        )}
      </Box>

      <NavbarProfile />
    </Toolbar>
  );
};
