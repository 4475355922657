import { useCallback, useEffect, useState } from 'react';

import type { BotTypeMessage } from 'api/tenantSettingsApi/tenantSettingsApi.types';
import { Nullable } from 'common/utils/assert';

import { Conversation, ConversationPayload } from '../../Chat.types';
import { buildChatConversation } from '../helpers';

import { Parser } from './parsers/Parser';

export const useChatConversation = <T extends ConversationPayload>(
  content: Nullable<string>,
  onSave: (content: string) => Promise<void>,
  payload?: ConversationPayload,
  predefinedConversation?: Conversation<T> | null
) => {
  const [conversation, setConversation] =
    useState<Nullable<Conversation<ConversationPayload>>>(null);

  useEffect(() => {
    (async () => {
      if (predefinedConversation) {
        setConversation(predefinedConversation);
      } else {
        const disassemble =
          content && Parser.isConversation(content)
            ? await Parser.disassemble<T>(content)
            : null;
        setConversation(disassemble);
      }
    })();
  }, [content, payload, predefinedConversation]);

  const addMessage = useCallback(
    async (message: BotTypeMessage) => {
      const updatedConversations = buildChatConversation<ConversationPayload>(
        message,
        conversation,
        payload
      );
      setConversation(updatedConversations);
      // Do not save only added message from user
      return updatedConversations;
    },
    [conversation, payload]
  );

  const replaceConversation = useCallback(
    async (newConversation: Conversation<T>) => {
      setConversation(newConversation);
      const newContent = await Parser.assemble(newConversation);
      await onSave(newContent);
      return newContent;
    },
    [onSave]
  );

  const clearConversation = useCallback(() => {
    setConversation(null);
  }, []);

  return {
    addMessage,
    clearConversation,
    conversation,
    replaceConversation,
  };
};
