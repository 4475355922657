import { useMemo } from 'react';

import { OrganizationMemberData } from 'api/organizationApi/OrganizationMembers.interface';
import { TagDetails } from 'api/tagsApi/tagsApi.types';
import { PaletteColorOption } from 'common/components/Fields/ColorPaletteField/ColorPalette.interface';
import { OrgRole } from 'containers/Auth/Auth.interface';
import { useTagShareSettingsInitialValues } from 'containers/TagShare/TagsShareSettings/hooks/useTagShareSettingsInitialValues';

import { TagSettingsFormValues } from '../TagSettingsForm.interface';

export type UseTagSettingsFormInitialValuesProps = {
  organization: OrgRole | null;
  tag: TagDetails;
  tagsPalette: PaletteColorOption[];
  teamMembers: OrganizationMemberData[];
};

export const useTagSettingsInitialValues = ({
  organization,
  tag,
  tagsPalette,
  teamMembers,
}: UseTagSettingsFormInitialValuesProps): TagSettingsFormValues => {
  const shareSettings = useTagShareSettingsInitialValues({
    organization,
    settings: tag.shareSettings,
    teamMembers,
  });

  const values = useMemo<TagSettingsFormValues>(() => {
    return {
      color: tag.color ?? tagsPalette[0].value,
      enableRecommendations: !tag.disableRecommendations,
      shareSettings,
      tagName: tag.name,
    };
  }, [
    tag.color,
    tag.disableRecommendations,
    tag.name,
    tagsPalette,
    shareSettings,
  ]);

  return values;
};
