import React, { FC } from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useStyles } from './styles';

interface Props {
  handleToggle: () => void;
  open: boolean;
}
export const ToggleChatListIconButton: FC<Props> = ({ handleToggle, open }) => {
  const classes = useStyles();

  return (
    <button
      aria-label={`toggle`}
      className={classes.toggleIcon}
      style={{ cursor: 'pointer' }}
      type="button"
      onClick={handleToggle}
    >
      {open ? <ExpandMoreIcon /> : <ChevronRightIcon />}
    </button>
  );
};
