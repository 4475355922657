import React, { FC } from 'react';

import { Box } from '@mui/material';

import {
  BotType,
  BotTypeMessage,
} from 'api/tenantSettingsApi/tenantSettingsApi.types';

import { ModeBotButton } from '../ModeBotButton/ModeBotButton';

export interface ModeButtonsProps {
  disabled?: boolean;
  onClick: (botMessage: BotTypeMessage) => void;
  supportedBotTypes: BotType[];
}

export const ModeButtons: FC<ModeButtonsProps> = ({
  disabled,
  onClick,
  supportedBotTypes,
}) => {
  // TODO: Fix it by using only bot from the tenantSettings > client_settings > pdf_viewer > chat !!!

  return (
    <Box
      data-testid="mode-buttons"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '0.5rem',
      }}
    >
      {supportedBotTypes.map((botType) => (
        <ModeBotButton
          botType={botType}
          disabled={disabled}
          key={botType}
          onClick={onClick}
        />
      ))}
    </Box>
  );
};
