import React, { FC, MutableRefObject, useRef } from 'react';

import { useIsVisible } from 'common/hooks/useIsVisible';

import { LoadingsRecord } from '../types';

import { ChatNoteItemContent } from './ChatNoteItemContent';
import { useChatNoteItem } from './hooks/useChatNoteItem';

interface Props {
  index: number;
  loadingsRef: MutableRefObject<LoadingsRecord>;
  onDelete: () => void;
  onPageFetched: (notesAmount: number) => void;
}

export const ChatNoteItem: FC<Props> = ({
  index,
  loadingsRef,
  onDelete,
  onPageFetched,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisible(ref);
  const { note } = useChatNoteItem({
    index,
    isVisible,
    loadingsRef,
    onPageFetched,
    ref,
  });

  return (
    <div ref={ref}>
      <ChatNoteItemContent note={note} onDelete={onDelete} />
    </div>
  );
};
