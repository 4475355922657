import React from 'react';

import { Box } from '@mui/material';
import { FormikProps } from 'formik';
import { FileWithPath } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { isFeatureEnabled } from 'common/components/FeatureFlags/Feature';
import { FileUploadField } from 'common/components/Fields/FileUploadField/FileUploadField';
import { FormTextField } from 'common/components/Fields/FormTextField';
import { ACCEPTED_FILE_TYPES } from 'common/constants/acceptedFileTypes';
import { Nullable } from 'common/utils/assert';

import { MoreForm } from './MoreForm';
import { PrivateDocFormValues } from './PrivateDocForm.interface';

type PrivateDocFormProps<T> = {
  loadingsStates?: Array<Nullable<'loading' | 'success' | 'error'>>;
} & FormikProps<PrivateDocFormValues & T>;

export const PrivateDocForm = <T,>({
  loadingsStates,
  ...props
}: PrivateDocFormProps<T>) => {
  const { t } = useTranslation(['common', 'privateDocs']);

  const isSingleDocument =
    !props?.values?.file?.length || props?.values?.file?.length === 1;

  const handleFileDrop = (files: FileWithPath[]) => {
    void props.setValues(
      (prevState) => ({
        ...prevState,
        abstractText: '',
        authors: '',
        source: '',
        title: '',
        uri: files.length > 1 ? '' : prevState.uri,
        year: '',
      }),
      false
    );
  };

  return (
    <Box>
      <Box>
        <FormTextField
          disabled={!isSingleDocument}
          label={t('privateDocs:form.uri.label')}
          margin="normal"
          name="uri"
          placeholder={t('privateDocs:form.uri.placeholder')}
        />

        <FileUploadField
          accept={
            isFeatureEnabled('ff-new-private-doc-types')
              ? ACCEPTED_FILE_TYPES
              : { 'application/pdf': ['.pdf'] }
          }
          label={t('privateDocs:form.file.label')}
          loadingsStates={loadingsStates}
          name="file"
          onFileDrop={handleFileDrop}
        />
      </Box>

      {isSingleDocument && <MoreForm />}
    </Box>
  );
};
