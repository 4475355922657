import { useCallback } from 'react';

import { useZoomLevel } from '@zarn/pdf-viewer';

import { AnnotationHighlight } from 'api/notesApi/notesApi.types';
import { ChunkHighlight } from 'common/interfaces/ChunkHighlight.interfaces';
import { Optional } from 'common/utils/assert';
import { compareDocuments } from 'common/utils/docIdHelpers';

import { getPageDimensions, getTopCoordinate, mapChunk } from '../helpers';

interface Props {
  docId: string;
  onAddHighlights: (highlights: AnnotationHighlight[]) => void;
  otherDocumentEvidenceChunkClick: (chunkId: string | null) => void;
  scrollTo: (top: number) => void;
}

export const useOnEvidenceChunkClick = ({
  docId,
  onAddHighlights,
  otherDocumentEvidenceChunkClick,
  scrollTo,
}: Props) => {
  const { zoomLevel } = useZoomLevel();

  const onEvidenceChunkClick = useCallback(
    (chunks: ChunkHighlight[], docIdOrChunkId: Optional<string>) => {
      const isSameDocument =
        docIdOrChunkId && compareDocuments(docId, docIdOrChunkId);

      if (isSameDocument) {
        const { height: pageHeight, width: pageWidth } = getPageDimensions();
        const temporaryHighlights = chunks.map(
          mapChunk(pageWidth, pageHeight, zoomLevel)
        );
        onAddHighlights(temporaryHighlights);

        const rect = chunks[0];
        if (!rect) {
          return;
        }
        scrollTo(
          getTopCoordinate({ ...rect, y1: rect.y1 }, getPageDimensions().height)
        );
      } else {
        otherDocumentEvidenceChunkClick(docIdOrChunkId ?? null);
      }
    },
    [
      onAddHighlights,
      scrollTo,
      zoomLevel,
      otherDocumentEvidenceChunkClick,
      docId,
    ]
  );

  return { onEvidenceChunkClick };
};
