import { RefObject, useEffect, useState } from 'react';

export const useIsVisible = (ref: RefObject<HTMLElement>) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    try {
      const observer = new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      );

      if (ref.current) {
        observer.observe(ref.current);
      }
      return () => {
        observer.disconnect();
      };
    } catch (error) {
      console.error('IntersectionObserver is not supported');
    }
  }, [ref]);

  return isIntersecting;
};
