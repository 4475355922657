import React, { useState, useEffect } from 'react';

import { Settings as SettingsIcon } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  BotConfiguration,
  ChatConfig,
} from 'api/tenantSettingsApi/tenantSettingsApi.types';
import { isFeatureEnabled } from 'common/components/FeatureFlags/Feature';
import { PartialRecord } from 'common/utils/types.helpers';

import { BotButton } from './BotButton';
import { BotConfigDialog } from './BotConfigDialog';
import {
  loadCustomBotsFromLocalStorage,
  loadVisibleBotsFromLocalStorage,
  saveCustomBotsToLocalStorage,
  saveVisibleBotsToLocalStorage,
} from './botStorage.utils';

interface BotTypeSelectorProps {
  botIdentifierToConfig?: PartialRecord<string, BotConfiguration>;
  chatConfig: ChatConfig;
  disabled?: boolean;
  initiallySelectedBotType?: string;
  onSelectBot: (botConfig: BotConfiguration) => void;
}

export const BotTypeSelector: React.FC<BotTypeSelectorProps> = ({
  botIdentifierToConfig,
  chatConfig,
  disabled = false,
  initiallySelectedBotType,
  onSelectBot,
}) => {
  const { t } = useTranslation('chat');
  const [selectedBotType, setSelectedBotType] = useState<string>(
    initiallySelectedBotType || chatConfig.defaultBotType || ''
  );
  const [isMoreDialogOpen, setIsMoreDialogOpen] = useState(false);
  const [customBots, setCustomBots] = useState<
    Record<string, BotConfiguration>
  >(() => loadCustomBotsFromLocalStorage());

  // Combine the original bot configs with custom bots
  const allBotIdentifierToConfig = React.useMemo(() => {
    if (!botIdentifierToConfig) return customBots;
    return { ...botIdentifierToConfig, ...customBots };
  }, [botIdentifierToConfig, customBots]);

  const handleBotTypeSelect = (botType: string) => {
    setSelectedBotType(botType);
    const botConfig = allBotIdentifierToConfig?.[botType];
    if (botConfig) {
      onSelectBot(botConfig);
    }
  };

  // Get all bot types including custom ones
  const allBotTypes: string[] = React.useMemo(
    () => [
      chatConfig.defaultBotType || 'default',
      ...(chatConfig.extraBotTypes || []).filter(
        (type) => type !== chatConfig.defaultBotType
      ),
      ...Object.keys(customBots),
    ],
    [chatConfig.defaultBotType, chatConfig.extraBotTypes, customBots]
  );

  const [visibleBots, setVisibleBots] = useState<Set<string>>(() => {
    const savedVisibleBots = loadVisibleBotsFromLocalStorage();
    return savedVisibleBots.size > 0 ? savedVisibleBots : new Set(allBotTypes);
  });

  // Update visible bots when custom bots are added
  useEffect(() => {
    setVisibleBots((prevVisibleBots) => {
      const newVisibleBots = new Set(prevVisibleBots);
      Object.keys(customBots).forEach((botType) => {
        newVisibleBots.add(botType);
      });
      return newVisibleBots;
    });
  }, [customBots]);

  useEffect(() => {
    saveCustomBotsToLocalStorage(customBots);
  }, [customBots]);

  useEffect(() => {
    saveVisibleBotsToLocalStorage(visibleBots);
  }, [visibleBots]);

  const handleToggleBot = (bot: BotConfiguration) => {
    const newVisibleBots = new Set(visibleBots);
    if (newVisibleBots.has(bot.tenantBotType)) {
      newVisibleBots.delete(bot.tenantBotType);
    } else {
      newVisibleBots.add(bot.tenantBotType);
    }
    setVisibleBots(newVisibleBots);
  };

  const handleCreateCustomBot = (newBot: BotConfiguration) => {
    setCustomBots((prev) => ({
      ...prev,
      [newBot.tenantBotType]: newBot,
    }));
  };

  return (
    <Box data-testid="BotTypeSelector">
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          marginBottom: '0.5rem',
        }}
      >
        {allBotTypes.map((botType) => {
          if (!allBotIdentifierToConfig || !visibleBots.has(botType)) {
            return null;
          }
          const config = allBotIdentifierToConfig[botType];
          const label = config?.label || botType;
          const icon = config?.icon;
          const description = config?.description || '';
          const isSelected = selectedBotType === botType;
          return (
            <BotButton
              description={description}
              disabled={disabled}
              key={botType}
              variant={isSelected ? 'contained' : 'outlined'}
              onClick={() => handleBotTypeSelect(botType)}
            >
              {icon && (
                <img
                  alt={`${label} icon`}
                  src={icon}
                  style={{
                    height: '28px',
                    marginRight: '8px',
                    objectFit: 'contain',
                    width: '28px',
                  }}
                />
              )}
              {label}
            </BotButton>
          );
        })}

        {isFeatureEnabled('ff-custom-bots') && (
          <BotButton
            description={t('chat.botSelector.more', 'Configure bots')}
            disabled={false}
            key={t('chat.botSelector.more', 'More')}
            variant="outlined"
            onClick={() => setIsMoreDialogOpen(true)}
          >
            {<SettingsIcon fontSize="small" sx={{ mr: '8px' }} />}
            {t('chat.botSelector.more', 'More')}
          </BotButton>
        )}
      </Box>

      <BotConfigDialog
        allBotTypes={allBotTypes}
        botIdentifierToConfig={allBotIdentifierToConfig}
        open={isMoreDialogOpen}
        visibleBots={visibleBots}
        onClose={() => setIsMoreDialogOpen(false)}
        onCreateCustomBot={handleCreateCustomBot}
        onToggleBot={handleToggleBot}
      />
    </Box>
  );
};
