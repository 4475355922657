import React, { FC } from 'react';

import { Alert, AlertTitle } from '@mui/material';

import { BaseError } from '../../models/Error.interface';

interface Props {
  error: Error | BaseError;
}

export const ErrorBox: FC<Props> = ({ error }) => (
  <Alert severity="error">
    <AlertTitle>{'name' in error ? error.name : 'Error'}</AlertTitle>
    {error.message}
  </Alert>
);
