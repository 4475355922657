import { useTenantSettings } from './useTenantSettings';

export const useAssertTenantSettings = () => {
  const data = useTenantSettings();

  const tenantSettings = data.assertTenantSettings();

  return {
    ...data,
    tenantSettings,
  };
};
