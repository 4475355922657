import { AxiosResponse } from 'axios';

import {
  ChatApi,
  ChatResponseForm,
  Configuration,
} from '@zarn/vendor/dist/search';

import { BASE_HEADERS, SERVICE_URL } from '../apiConfig';
import AxiosInstance, { tryToGetRefreshedToken } from '../axiosInstance';

import { ChatResponse } from './chatApi.types';
import { deserializeChatResponse } from './chatApi.utils';

const chatApi = new ChatApi(
  new Configuration({ baseOptions: BASE_HEADERS, basePath: SERVICE_URL }),
  SERVICE_URL,
  AxiosInstance
);

export const requestChatAnswer = async (
  chatResponseForm: ChatResponseForm,
  tenant: string,
  indexCluster?: string
): Promise<AxiosResponse<ChatResponse>> => {
  const storedAccessToken = await tryToGetRefreshedToken();
  if (!storedAccessToken) {
    throw new Error('Access Token does not exist');
  }

  const { data, ...response } = await chatApi.respond(
    {
      chatResponseForm,
      indexCluster,
      requesterUuid: '',
      tenant,
      userRoles: '',
    },
    {
      headers: {
        Authorization: `Bearer ${storedAccessToken}`,
        'Content-Type': 'application/json',
      },
    }
  );

  return { ...response, data: deserializeChatResponse(data) };
};
