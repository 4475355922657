import React, { FC } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  onClose: () => void;
  onDelete: () => void;
  popover: HTMLButtonElement | null;
}

export const ChatSettingsPopover: FC<Props> = ({
  onClose,
  onDelete,
  popover,
}) => {
  const { t } = useTranslation('pages');

  return (
    <Menu
      anchorEl={popover}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      data-testid="ChatSettingsPopover"
      open={!!popover}
      transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      onClose={onClose}
    >
      <MenuItem data-testid="deleteChat" onClick={() => onDelete()}>
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>

        <ListItemText primary={t('chat.deleteChat')} />
      </MenuItem>
    </Menu>
  );
};
