import { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { listNotes, updateNote } from 'api/notesApi/notesApi';
import { NoteDetails } from 'api/notesApi/notesApi.types';
import { Nullable } from 'common/utils/assert';
import { useParsedHostname } from 'common/utils/useParsedHostname';

export const useChatNote = (
  noteId: Nullable<number | string>,
  createNote: (content: string) => Promise<NoteDetails>
) => {
  const { enqueueSnackbar } = useSnackbar();
  const { tenant } = useParsedHostname();

  const [note, setNote] = useState<NoteDetails | null>(null);

  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Nullable<Error>>(null);

  const loadNote = useCallback(async () => {
    if (!noteId) {
      return Promise.reject(new Error('Note ID is not defined'));
    }
    const { data } = await listNotes({
      noteIds: [+noteId],
      tenant,
    });
    const loadedNote = data.items[0];
    if (!loadedNote) {
      return Promise.reject(new Error('Note not found'));
    }
    if (!loadedNote.content) {
      return Promise.reject(new Error('Note is not a conversation'));
    }
    setNote(loadedNote);
  }, [noteId, tenant]);

  const tryLoadNote = useCallback(async () => {
    try {
      setIsLoading(true);
      await loadNote();
    } catch (e) {
      setError(e as Error);
      return Promise.reject(e);
    } finally {
      setIsLoading(false);
    }
  }, [loadNote]);

  const saveNote = useCallback(
    async (content: string) => {
      if (!noteId) {
        const newNote = await createNote(content);
        enqueueSnackbar('Chat note is created', {
          variant: 'success',
        });
        setNote(newNote);
      } else {
        if (!note) {
          enqueueSnackbar('Note is not loaded for saving', {
            variant: 'error',
          });
          return Promise.reject('Note is not loaded for saving');
        }
        await updateNote({
          ...note,
          content,
          noteId: note.id,
          tenant,
        });

        setNote({
          ...note,
          content,
        });
      }
    },
    [createNote, enqueueSnackbar, note, noteId, tenant]
  );

  const trySaveNote = useCallback(
    async (content: string) => {
      try {
        setIsSaving(true);
        await saveNote(content);
      } catch (e) {
        setError(e as Error);
        enqueueSnackbar(e, {
          variant: 'error',
        });
        return Promise.reject(e);
      } finally {
        setIsSaving(false);
      }
    },
    [enqueueSnackbar, saveNote]
  );

  useEffect(() => {
    if (noteId) {
      void tryLoadNote();
    } else {
      setNote(null);
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    }
  }, [noteId, tryLoadNote]);

  return {
    error,
    isLoading,
    isSaving,
    note,
    trySaveNote,
  };
};
