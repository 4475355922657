import { useLocation } from 'react-router';

interface Props {
  docId?: string | null;
}

export const useIsInsidePdfViewer = ({ docId }: Props = { docId: '' }) => {
  const location = useLocation();
  const isPdfOpen = location.pathname.includes('pdf/' + docId);

  return { isPdfOpen };
};
