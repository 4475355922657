import React, { FC } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Tooltip } from '@mui/material';
import { IconButtonProps } from '@mui/material/IconButton/IconButton';

import { useStyles } from './styles';

export const RemoveButton: FC<IconButtonProps> = ({
  size = 'small',
  ...props
}) => {
  const classes = useStyles();

  return (
    <Tooltip title="Delete attachment">
      <IconButton className={classes.deleteButton} size={size} {...props}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};
