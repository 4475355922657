import { useHistory, useLocation } from 'react-router';

export const useChatPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const noteId = params.get('noteId');
  const isChatPage = location.pathname.startsWith('/chat');

  const history = useHistory();
  const openChatPage = () => {
    history.push('/chat');
  };

  return { chatNoteId: noteId, isChatPage, openChatPage };
};
