import React, { FC, KeyboardEvent, useCallback, useRef } from 'react';

import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import { Box, InputAdornment } from '@mui/material';
import { Form, Formik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import { useTranslation } from 'react-i18next';

import { FormTextField } from 'common/components/Fields/FormTextField';
import { isExist } from 'common/utils/assert';

import { isFeatureEnabled } from '../../../common/components/FeatureFlags/Feature';
import { prepareUserMessage } from '../Chat.utils';

import { AttachmentStrategy } from './Attachment/AttachmentStrategy';
import { UploadFilesButton } from './Attachment/UploadFilesButton';
import { useAttachments } from './useAttachments';

interface MessageInputValues {
  message: string;
}

export interface MessageInputProps {
  disabled?: boolean;
  isLoading?: boolean;
  onSubmit: (value: string, documentsIds: string[]) => void;
  placeholder?: string;
}

const initialValues = {
  message: '',
};

export const MessageInput: FC<MessageInputProps> = ({
  disabled,
  isLoading,
  onSubmit,
  placeholder,
}) => {
  const { t } = useTranslation('common');
  const sendButtonRef = useRef<HTMLButtonElement>(null);

  const {
    addAttachments,
    attachments,
    clearAttachments,
    isUploading,
    removeAttachment,
  } = useAttachments();

  const handleSubmit = useCallback(
    (
      values: MessageInputValues,
      { resetForm }: FormikHelpers<MessageInputValues>
    ) => {
      if (isUploading) {
        return;
      }
      const message = prepareUserMessage(values.message);
      if (!message) {
        return;
      }

      const documentsIds = attachments
        .filter(isExist)
        .map((attachment) => attachment.data)
        .filter(isExist)
        .map(({ organizeDocId }) => organizeDocId);

      onSubmit(message, documentsIds);
      resetForm();
      clearAttachments();
    },
    [attachments, clearAttachments, isUploading, onSubmit]
  );

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (isUploading) {
      return;
    }
    if (event.key !== 'Enter') {
      return;
    }
    if (event.shiftKey) {
      // Shift+Enter: Add a new line
      return;
    }
    event.preventDefault(); // Prevents default Enter behavior
    sendButtonRef.current?.click();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Form name="message-form" style={{ width: '100%' }}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 1 }}>
            {attachments.map((attachment, index) => (
              <AttachmentStrategy
                attachment={attachment}
                key={index}
                onRemove={() => removeAttachment(index)}
              />
            ))}
          </Box>
          <FormTextField
            autoComplete="off"
            data-testid="chat-field"
            disabled={disabled}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <LoadingButton
                    color="secondary"
                    data-testid="chat-send-button"
                    disabled={disabled || isUploading}
                    loading={isLoading}
                    ref={sendButtonRef}
                    size="small"
                    sx={{ minWidth: 32 }}
                    type="submit"
                  >
                    <SendIcon />
                  </LoadingButton>
                </InputAdornment>
              ),
              onKeyDown: handleKeyDown,
              startAdornment: isFeatureEnabled('ff-chat-attachments') ? (
                <InputAdornment position="start">
                  <UploadFilesButton onSelectFiles={addAttachments} />
                </InputAdornment>
              ) : null,
            }}
            name="message"
            placeholder={placeholder ?? t('chat.message.input.placeholder')}
            size="small"
            sx={{
              '& div': { backgroundColor: '#fff' },
              '& input': { background: '#fff', borderRadius: 2.5 },
            }}
            testId="chat-input"
            variant="outlined"
            autoFocus
            multiline
          />
        </Box>
      </Form>
    </Formik>
  );
};
