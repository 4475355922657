import { alpha, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  attachmentBox: {
    alignItems: 'center',
    backgroundColor: alpha(theme.palette.grey[300], 0.4),
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    margin: theme.spacing(1),
    padding: theme.spacing(0.5, 1),
    position: 'relative',
  },
  badge: {
    '& .MuiBadge-badge': {
      height: '16px',
      minWidth: '16px',
      padding: 0,
      right: -8,
      top: -4,
    },
  },
}));
