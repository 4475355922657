import { useMemo } from 'react';

import {
  BotIdentifier,
  BotType,
} from 'api/tenantSettingsApi/tenantSettingsApi.types';
import { useTenantSettings } from 'common/hooks/useTenantSettings';
import { Optional } from 'common/utils/assert';

export const useBotTypeConfiguration = (
  botType: Optional<BotType>,
  identifier?: BotIdentifier
) => {
  const { tenantSettings } = useTenantSettings();

  const config = useMemo(() => {
    const configuration = tenantSettings?.chat.botTypesConfiguration;
    if (!configuration) {
      return null;
    }
    const configByBotType = botType && configuration[botType];
    if (configByBotType) {
      return configByBotType;
    }
    if (!identifier) {
      return null;
    }
    const configByIdentifier = Object.values(configuration).find(
      (botConfig) => botConfig?.identifier === identifier
    );
    if (!configByIdentifier) {
      return null;
    }
    return configByIdentifier;
  }, [tenantSettings, botType, identifier]);

  const label = useMemo(() => config?.label ?? null, [config]);

  return { config, label };
};
