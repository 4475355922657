import React, { FC, ReactNode } from 'react';

import { ErrorBox } from 'common/components/ErrorBox/ErrorBox';

import { useDocDetails } from '../Docs/hooks/useDocDetails';

import { RetrievalUnitData } from './RetrievalUnitData.interface';
import { RetrievalUnitSkeleton } from './RetrievalUnitSkeleton';

interface Props {
  docId: string;
  hits?: RetrievalUnitData[];
  loadingComponent?: ReactNode;
  render: (document: RetrievalUnitData) => ReactNode;
}

export const LoadRetrievalUnit: FC<Props> = ({
  docId,
  hits,
  loadingComponent = <RetrievalUnitSkeleton />,
  render,
}) => {
  const { data: document, error, isLoading } = useDocDetails({ docId, hits });

  if (document) {
    return <>{render(document)}</>;
  }

  if (isLoading) {
    return <>{loadingComponent}</>;
  }

  if (error) {
    return <ErrorBox error={error} />;
  }

  return null;
};
