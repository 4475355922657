import { useCallback } from 'react';

import { useLocation } from 'react-router';

import { ChatContext } from 'api/chatApi/chatApi.types';
import { BotTypeMessage } from 'api/tenantSettingsApi/tenantSettingsApi.types';
import { useNavigationWithState } from 'common/hooks/useNavigationWithState';
import { useQuerySearchParams } from 'common/hooks/useQuerySearchParams';
import { Nullable } from 'common/utils/assert';

import { Conversation, ConversationPayload } from '../Chat.types';

import { useAutoSendingExplanationText } from './useAutoSendingExplanationText';
import { useAutoSendingTagExplanation } from './useAutoSendingTagExplanation';
import { useChatSending } from './useChatSending';
import { useChatConversation } from './useConversation/useChatConversation';

interface UseChatProps<T extends ConversationPayload> {
  content: Nullable<string>;
  context: ChatContext;
  onSave: (content: string) => Promise<void>;
  payload?: ConversationPayload;
  predefinedConversation?: Conversation<T> | null;
}

export const useChat = <T extends ConversationPayload>({
  content,
  context,
  onSave,
  payload,
  predefinedConversation,
}: UseChatProps<T>) => {
  const { replace } = useNavigationWithState();

  const { addMessage, clearConversation, conversation, replaceConversation } =
    useChatConversation<ConversationPayload>(
      content,
      onSave,
      payload,
      predefinedConversation
    );

  const botParams = conversation?.botParams;

  const { error, isSending, loadingMessage, sendConversation } =
    useChatSending<ConversationPayload>({
      botParams,
      context,
    });

  const { setParams } = useQuerySearchParams();

  const location = useLocation();

  const onReset = useCallback(() => {
    clearConversation();
    // TODO: remove it from the chat
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    params.delete('noteId');
    url.search = params.toString();
    replace(`${location.pathname}${url.search}`);
    setParams({
      tab: 'chat',
    });
  }, [location.pathname, clearConversation, replace, setParams]);

  const onSendMessage = useCallback(
    async (botMessage: BotTypeMessage) => {
      const newConversation = await addMessage(botMessage);
      const updatedConversation = await sendConversation(newConversation);
      if (updatedConversation) {
        await replaceConversation(updatedConversation);
      } else {
        clearConversation();
      }
    },
    [addMessage, clearConversation, replaceConversation, sendConversation]
  );

  useAutoSendingExplanationText(onSendMessage, clearConversation);
  useAutoSendingTagExplanation(
    onSendMessage,
    !!conversation || isSending || !!error
  );

  return {
    conversation,
    error,
    isSending,
    loadingMessage,
    onReset,
    onSendMessage,
  };
};
