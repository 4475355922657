import React from 'react';

import EmailIcon from '@mui/icons-material/Email';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TagDetails } from 'api/tagsApi';
import BlueskyIcon from 'common/components/CustomIcons/BlueskyIcon';
import TagShareLinkCopyField from 'common/components/Share/ShareSettings/TagShareLinkCopyField/TagShareLinkCopyField';
import ShareViaEmailDialog from 'common/components/ShareDocuments/ShareViaEmailDialog/ShareViaEmailDialog';
import { ShareEnum } from 'common/enums';
import { useTenantFeatures } from 'common/hooks/useTenantFeatures';
import { useShareTagByEmail } from 'containers/TagShare/hooks/useShareTagByEmail';

import { useShareTagViaBluesky } from '../hooks/useShareTagViaBluesky';

type TagShareLinkActionsProps = {
  tag: TagDetails;
};

const TagShareLinkActions = ({ tag }: TagShareLinkActionsProps) => {
  const { t } = useTranslation(['common']);
  const {
    getShareLink,
    hasNotes,
    notesLoading,
    onNotesInclude,
    setShareDialogOpen,
    shareDialogOpen,
  } = useShareTagByEmail({ tagId: tag.id, tagType: tag.type });
  const onShareViaBluesky = useShareTagViaBluesky(tag.id, tag.name);

  const handleShareByEmail = () => {
    if (hasNotes) {
      setShareDialogOpen(true);
      return;
    }

    window.open(getShareLink(ShareEnum.Email));
  };

  const { isShareToPublicInTag } = useTenantFeatures();

  return (
    <Box alignItems="center" display="flex" gap={1}>
      <TagShareLinkCopyField tagId={tag.id} />
      {isShareToPublicInTag && (
        <Tooltip title={t<string>('common:share.options.bluesky')}>
          <IconButton size="large" onClick={onShareViaBluesky}>
            <BlueskyIcon />
          </IconButton>
        </Tooltip>
      )}

      <Tooltip title={t<string>('common:share.options.email')}>
        <IconButton size="large" onClick={handleShareByEmail}>
          <EmailIcon sx={{ color: 'email' }} />
        </IconButton>
      </Tooltip>

      <ShareViaEmailDialog
        getShareLink={getShareLink}
        notesLoading={notesLoading}
        open={shareDialogOpen}
        setOpen={setShareDialogOpen}
        onNotesInclude={onNotesInclude}
      />
    </Box>
  );
};

export default TagShareLinkActions;
