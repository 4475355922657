const LS_QA_RESULT_HIDDEN = 'qaResultHidden';

export const setHideQAResultToLS = (state: boolean): void => {
  if (state) {
    localStorage.setItem(LS_QA_RESULT_HIDDEN, String(state));
  } else {
    localStorage.removeItem(LS_QA_RESULT_HIDDEN);
  }
};

export const getQAResultVisibilityFromLS = (): boolean => {
  return !!localStorage.getItem(LS_QA_RESULT_HIDDEN);
};
