import {
  MutableRefObject,
  UIEventHandler,
  useCallback,
  useRef,
  useState,
} from 'react';

import { throttle } from 'common/utils/throttle';

export const useChatScrolling = (
  chatMessagesRef: MutableRefObject<HTMLDivElement | null>
) => {
  const [isEnable, setIsEnable] = useState(true);
  const scrollToRef = useRef(0);

  const scrollTo = useCallback(
    (top: number) => {
      chatMessagesRef.current?.scroll({
        behavior: 'smooth',
        top,
      });
    },
    [chatMessagesRef]
  );

  const scrollToBottom = useCallback(
    (scrollHeight: number) => {
      if (!isEnable) {
        return;
      }
      scrollTo(scrollHeight + 1000);
    },
    [scrollTo, isEnable]
  );

  const scrollToBottomSlowly = useCallback(
    throttle(() => {
      if (!chatMessagesRef.current || !isEnable) {
        return;
      }
      scrollToBottom(chatMessagesRef.current.scrollHeight);
    }, 100),
    [scrollToBottom, isEnable]
  );

  const onScroll: UIEventHandler<HTMLDivElement> = useCallback((event) => {
    const scrollTop = event.currentTarget.scrollTop;
    setIsEnable(scrollToRef.current < scrollTop);
    scrollToRef.current = scrollTop;
  }, []);

  const enableScrolling = useCallback(() => {
    setIsEnable(true);
  }, []);

  return {
    enableScrolling,
    onScroll,
    scrollToBottom,
    scrollToBottomSlowly,
  };
};
