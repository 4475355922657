import React, { FC, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Drawer,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { OpenFullChatButton } from 'containers/Chat/OpenFullChatButton/OpenFullChatButton';

import { useStyles } from './styles';

export interface VerticalDrawerProps {
  actions?: React.ReactNode;
  anchor?: 'left' | 'right' | undefined;
  chatTab: boolean;
  children: React.ReactNode;
  onClose: () => void;
}

export const VerticalDrawer: FC<VerticalDrawerProps> = ({
  actions,
  anchor,
  chatTab,
  children,
  onClose,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation('common');
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [expanded, setExpanded] = useState(true);

  const handleToggleView = () => {
    setExpanded((value) => !value);
  };

  return (
    <Drawer
      anchor={anchor}
      classes={{
        paper: clsx(classes.drawerPaper, isMobile && classes.drawerPaperMobile),
      }}
      ModalProps={{
        keepMounted: true,
      }}
      variant="persistent"
      open
    >
      <div
        className={clsx(
          classes.sidebar,
          anchor === 'right' && classes.sidebarRight
        )}
      >
        <div className={clsx(classes.section, classes.header)}>
          <IconButton
            aria-label="close"
            color="inherit"
            size="large"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          {chatTab && <OpenFullChatButton />}
        </div>
        {actions}
        <div className={classes.section}>
          <Tooltip
            placement={anchor}
            title={
              expanded
                ? t('verticalDrawer.toggle.hide')
                : t('verticalDrawer.toggle.show')
            }
          >
            <IconButton size="large" onClick={handleToggleView}>
              {expanded ? <ArrowForwardIcon /> : <ArrowBackIcon />}
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <Box
        className={clsx(
          classes.content,
          anchor === 'right' && classes.contentRight,
          !expanded && classes.contentMinified
        )}
        flex={1}
        hidden={!expanded}
      >
        {children}
      </Box>
    </Drawer>
  );
};

VerticalDrawer.defaultProps = {
  anchor: 'right',
};
