import React, { FC } from 'react';

import { ButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { QuizbotBotTypeMessage } from 'api/tenantSettingsApi/tenantSettingsApi.types';
import TrackedButton from 'common/components/Buttons/TrackedButton/TrackedButton';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';

import { useBotTypeConfiguration } from '../hooks/useBotTypeConfiguration';
import { useChatMessages } from '../hooks/useChatMessages';

interface Props extends Omit<ButtonProps, 'onClick'> {
  onClick: (botMessage: QuizbotBotTypeMessage) => void;
}

export const QuizbotModeBotButton: FC<Props> = ({ onClick, ...props }) => {
  const { t } = useTranslation(['common']);
  const quizBotConfig = useBotTypeConfiguration(null, 'quizbot');
  const { buildQuizMessage } = useChatMessages(
    quizBotConfig.config?.tenantBotType
  );

  const handleClick = () => onClick(buildQuizMessage());

  return (
    <TrackedButton
      color="secondary"
      data-testid="quizbot-modeButton"
      eventName={TrackEventName.QuizbotStartClicked}
      size="small"
      sx={{ margin: '0.5rem' }}
      variant="contained"
      onClick={handleClick}
      {...props}
    >
      {quizBotConfig.label || t(`common:chat.modeButtons.quizbot`)}
    </TrackedButton>
  );
};
