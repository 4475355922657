import React, { FC } from 'react';

import { CircularProgress } from '@mui/material';

import { Nullable } from 'common/utils/assert';

import { IAttachment } from '../IAttachment';

import { Attachment } from './Attachment';
import { RemoveButton } from './RemoveButton/RemoveButton';

interface Props {
  attachment: Nullable<IAttachment>;
  onRemove: () => void;
}

export const AttachmentStrategy: FC<Props> = ({ attachment, onRemove }) => {
  if (!attachment) {
    return null;
  }

  if (attachment.error) {
    return (
      <Attachment
        button={<RemoveButton color="error" onClick={onRemove} />}
        sx={{ color: 'red' }}
        title={attachment.file.name}
        tooltip={attachment.error.message}
      />
    );
  }

  if (attachment.isUploading) {
    return (
      <Attachment
        button={<CircularProgress size={18} />}
        title={attachment.file.name}
        tooltip="Uploading file..."
      />
    );
  }

  return (
    <Attachment
      button={<RemoveButton onClick={onRemove} />}
      title={attachment.file.name}
    />
  );
};
