import { useCallback } from 'react';

import { useNavigationWithState } from 'common/hooks/useNavigationWithState';
import { useLoggedInFeature } from 'containers/Auth/hooks/useLoggedInFeature';

interface Props {
  noteId: string | null;
  onOpenChatClick?: () => void;
}

export const useOpenChatOnChatPage = ({ noteId, onOpenChatClick }: Props) => {
  const navigation = useNavigationWithState();

  const openChatNote = useLoggedInFeature<boolean>(() => {
    if (noteId) {
      navigation.push(`/chat?noteId=${noteId}`);
    } else {
      navigation.push('/chat');
    }
  });

  const openChatPageChat = useCallback(async () => {
    onOpenChatClick?.();

    openChatNote(true);
  }, [onOpenChatClick, openChatNote]);

  return { openChatPageChat };
};
