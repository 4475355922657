export const setFavicon = (url: string) => {
  const link: HTMLLinkElement | null =
    document.querySelector("link[rel*='icon']") ||
    document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = url;

  if (!document.querySelector("link[rel*='icon']")) {
    document.head.appendChild(link);
  }
};
