import React, {
  createContext,
  useContext,
  useState,
  FC,
  ReactNode,
  useCallback,
  useEffect,
} from 'react';

import {
  setHideQAResultToLS,
  getQAResultVisibilityFromLS,
} from './hideQAResults.utils';

interface QAHideResultContextProps {
  isResultHidden: boolean;
  openResult: () => void;
  toggleResultVisibility: () => void;
}

const QAHideResultContext = createContext<QAHideResultContextProps | undefined>(
  undefined
);

export const QAHideResultProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isResultHidden, setIsResultHidden] = useState(
    getQAResultVisibilityFromLS
  );

  const toggleResultVisibility = useCallback(() => {
    setIsResultHidden((prev: boolean) => !prev);
  }, []);

  const openResult = useCallback(() => {
    setIsResultHidden(false);
  }, []);

  useEffect(() => {
    setHideQAResultToLS(isResultHidden);
  }, [isResultHidden]);

  return (
    <QAHideResultContext.Provider
      value={{ isResultHidden, openResult, toggleResultVisibility }}
    >
      {children}
    </QAHideResultContext.Provider>
  );
};

export const useQAHideResult = (): QAHideResultContextProps => {
  const context = useContext(QAHideResultContext);
  if (!context) {
    throw new Error(
      'useQAHideResult must be used within a QAHideResultProvider'
    );
  }
  return context;
};
