import { useCallback, useEffect, useState } from 'react';

import { captureException } from '@sentry/react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { NoteObjectType } from '@zarn/vendor/dist/saved-results';

import { createNote } from 'api/notesApi/notesApi';
import { NoteDetails } from 'api/notesApi/notesApi.types';
import { deserializeSharing } from 'api/notesApi/notesApi.utils';
import { deserializeAxiosError } from 'common/utils/error';
import { useParsedHostname } from 'common/utils/useParsedHostname';

interface UseSaveAsNoteProps {
  noteContent: string | null;
  noteObjectType: NoteObjectType;
}

export const useSaveAsNote = ({
  noteContent,
  noteObjectType,
}: UseSaveAsNoteProps) => {
  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const [createdNote, setCreatedNote] = useState<NoteDetails | null>(null);
  const [content, setContent] = useState<string | null>(null);
  const parsedHostname = useParsedHostname();

  useEffect(() => {
    if (!noteContent || noteContent === content) {
      return;
    }
    setContent(noteContent);
    setCreatedNote(null);
  }, [noteContent, content]);

  const save = useCallback(
    async (localContent?: string | null) => {
      const newContent = localContent ?? noteContent;
      if (!newContent) {
        return;
      }
      try {
        if (localContent) {
          setContent(localContent);
        }
        const { data } = await createNote({
          annotationHighlight: null,
          content: newContent,
          objectId: null,
          objectType: noteObjectType,
          sharing: deserializeSharing(),
          tenant: parsedHostname.tenant,
        });
        setCreatedNote(data);
        enqueueSnackbar(t('notes.create.successMessage'));
        return data;
      } catch (error) {
        captureException(error);
        const errMessage = deserializeAxiosError(error).message;
        enqueueSnackbar(errMessage, {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, noteContent, noteObjectType, parsedHostname.tenant, t]
  );

  return { createdNote, saveAsNote: save };
};
