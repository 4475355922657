import { AxiosResponse } from 'axios';

import { Configuration, OrganizationsApi } from '@zarn/vendor/dist/auth';

import { PaginatedResults } from 'api/models/PaginatedResults';
import {
  GetOrganizationMembersPayload,
  ListOrganizationMembersPayload,
  OrganizationMemberData,
  OrganizationMembersResultsData,
} from 'api/organizationApi/OrganizationMembers.interface';

import { BASE_HEADERS, SERVICE_URL } from '../apiConfig';
import axiosInstance from '../axiosInstance';

import { deserializeOrganizationMember } from './organizationMembers.utils';

export const organizationsApi = new OrganizationsApi(
  new Configuration({ baseOptions: BASE_HEADERS, basePath: SERVICE_URL }),
  SERVICE_URL,
  axiosInstance
);

export const listOrganizationMembers = async (
  payload: ListOrganizationMembersPayload
): Promise<AxiosResponse<OrganizationMembersResultsData>> => {
  const { data, ...rest } = await organizationsApi.listOrganizationMembers({
    requesterUuid: '',
    userRoles: '',
    ...payload,
  });

  return {
    ...rest,
    data: new PaginatedResults<OrganizationMemberData>(
      data.count,
      data.results.map(deserializeOrganizationMember),
      data.next?.page,
      data.previous?.page,
      payload.page,
      payload.pageSize
    ),
  };
};

export const getOrganizationMember = async ({
  memberId,
  organizationId,
  tenant,
}: GetOrganizationMembersPayload): Promise<
  AxiosResponse<OrganizationMemberData>
> => {
  const { data, ...rest } = await organizationsApi.retrieveOrganizationMember({
    organizationId: organizationId,
    requesterUuid: '',
    tenant,
    userRoles: '',
    userUuid: memberId,
  });

  return {
    ...rest,
    data: deserializeOrganizationMember(data),
  };
};
