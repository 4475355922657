import {
  OrgSharingPolicy,
  ResourcePermission,
  SharingPolicy,
  UserSharingPolicy,
} from '@zarn/vendor/dist/saved-results';

import {
  SharingOrgPolicy,
  SharingPermission,
  SharingPolicies,
  SharingUserPolicy,
} from './Sharing.types';

export const deserializeResourcePermission = (
  resourcePermission: ResourcePermission
): SharingPermission => {
  switch (resourcePermission) {
    case ResourcePermission.Read:
      return 'read';
    case ResourcePermission.Write:
      return 'write';
    case ResourcePermission.Modify:
      return 'modify';
  }
};

export const serializeResourcePermission = (
  resourcePermission: SharingPermission
): ResourcePermission => {
  switch (resourcePermission) {
    case 'read':
      return ResourcePermission.Read;
    case 'write':
      return ResourcePermission.Write;
    case 'modify':
      return ResourcePermission.Modify;
  }
};

export const deserializeUserSharingPolicy = (
  userSharing: UserSharingPolicy
): SharingUserPolicy => ({
  id: userSharing.user_uuid,
  permission: deserializeResourcePermission(userSharing.permission),
});

export const deserializeOrgSharingPolicy = (
  orgSharing: OrgSharingPolicy
): SharingOrgPolicy => ({
  id: orgSharing.org_id,
  permission: deserializeResourcePermission(orgSharing.permission),
});

export const serializeUserSharingPolicy = (
  userSharing: SharingUserPolicy
): UserSharingPolicy => ({
  permission: serializeResourcePermission(userSharing.permission),
  user_uuid: userSharing.id,
});

export const serializeOrgSharingPolicy = (
  orgSharing: SharingOrgPolicy
): OrgSharingPolicy => ({
  org_id: orgSharing.id,
  permission: serializeResourcePermission(orgSharing.permission),
});

export const deserializeSharingPolicy = (
  sharing: SharingPolicy
): SharingPolicies => ({
  orgs: (sharing.orgs ?? []).map(deserializeOrgSharingPolicy),
  public: sharing.public && { permission: 'read' },
  users: (sharing.users ?? []).map(deserializeUserSharingPolicy),
});

export const serializeSharingPolicy = (
  sharing: SharingPolicies
): SharingPolicy => ({
  orgs: sharing.orgs.length
    ? sharing.orgs.map(serializeOrgSharingPolicy)
    : undefined,
  public: sharing.public && {
    permission: serializeResourcePermission(sharing.public.permission),
  },
  users: sharing.users.length
    ? sharing.users.map(serializeUserSharingPolicy)
    : undefined,
});
