import { captureException } from '@sentry/react';
import { enqueueSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';

import { deleteNote } from 'api/notesApi/notesApi';
import { useParsedHostname } from 'common/utils/useParsedHostname';

export const useDeleteChat = () => {
  const { tenant } = useParsedHostname();
  const queryClient = useQueryClient();

  const deleteMutation = useMutation(
    async (_noteId: number) => {
      await deleteNote(_noteId, tenant);
      return _noteId;
    },
    {
      onError: (err) => {
        captureException(err);
        enqueueSnackbar('Failed to delete chat note', {
          variant: 'error',
        });
        throw err;
      },
      onSuccess: (_noteId) => {
        enqueueSnackbar('Chat deleted', { variant: 'success' });
        return queryClient.invalidateQueries(['chatNote', _noteId.toString()]);
      },
    }
  );

  return {
    deleteChat: deleteMutation.mutate,
  };
};
