import { useMemo } from 'react';

import { OrganizationMemberData } from 'api/organizationApi/OrganizationMembers.interface';

import { getInitials } from '../../../../../utils/strings.utils';
import {
  ShareOrgOptionValue,
  ShareSelectionOptionValue,
  ShareUserOptionValue,
} from '../ShareSelection.interface';

export interface UseShareSelectionOptionsProps {
  accountName: string;
  initials: string;
  selected: ShareSelectionOptionValue[];
  userRoleId: number;
  users: OrganizationMemberData[];
}

export const useShareSelectionOptions = ({
  accountName,
  initials,
  selected,
  userRoleId,
  users,
}: UseShareSelectionOptionsProps) => {
  return useMemo<ShareSelectionOptionValue[]>(() => {
    const orgOptions: ShareOrgOptionValue[] = !selected?.some(
      (value) => value.id === userRoleId
    )
      ? [
          {
            id: userRoleId,
            initials,
            name: accountName,
            notify: false,
            permission: 'read',
            type: 'organization',
          },
        ]
      : [];

    const userOptions: ShareUserOptionValue[] = users
      ?.filter(({ uuid }) => !selected?.find((value) => value.id === uuid))
      .map((user) => ({
        email: user.email,
        id: user.uuid,
        initials:
          user.initials ??
          getInitials(`${user.firstName} ${user.lastName}`) ??
          '',
        name: `${user.firstName} ${user.lastName}`,
        notify: true,
        permission: 'read',
        type: 'user',
      }));

    return [...orgOptions, ...userOptions];
  }, [selected, accountName, initials, userRoleId, users]);
};
