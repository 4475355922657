import React, { FC } from 'react';

import { Box, ButtonProps, Tooltip } from '@mui/material';

import TrackedButton from 'common/components/Buttons/TrackedButton/TrackedButton';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';

interface Props extends ButtonProps {
  description: string;
}

export const BotButton: FC<Props> = ({ children, description, ...props }) => {
  return (
    <Tooltip title={description}>
      <Box>
        <TrackedButton
          color="secondary"
          data-testid="BotButton"
          eventName={TrackEventName.BotTypeSelectorClicked}
          size="small"
          sx={{ margin: '0.5rem', transition: 'none' }}
          title={description}
          variant="contained"
          {...props}
        >
          {children}
        </TrackedButton>
      </Box>
    </Tooltip>
  );
};
