import { NoteDetails } from 'api/notesApi/notesApi.types';

const extractSnippetFromImage = (content: string, userPattern: RegExp) => {
  const userMessages = content.match(
    new RegExp(userPattern.source + '(.*)', 'g')
  );
  return userMessages && userMessages.length > 1
    ? userMessages[1].replace(userPattern, '').trim()
    : '';
};

const extractSnippetFromUserMessage = (
  content: string,
  userPattern: RegExp
) => {
  const userMessageMatch = content.match(
    new RegExp(userPattern.source + '(.*)')
  );
  return userMessageMatch && userMessageMatch[1]
    ? userMessageMatch[1].replace(userPattern, '').trim()
    : '';
};

const extractSnippetFromGeneralMessage = (content: string) => {
  const match = content.match(/> (.*)/);
  return match && match[1] ? match[1].trim() : '';
};

const matchPattern = (content: string) => {
  const userPattern = /\*\*user:\*\*\n\n\s*> /;
  const imageMatch = content.match(/!\[Image\]\(data:image.*?\)/);
  if (imageMatch) {
    return extractSnippetFromImage(content, userPattern);
  }
  return (
    extractSnippetFromUserMessage(content, userPattern) ||
    extractSnippetFromGeneralMessage(content)
  );
};

export const extractChatSnippet = (content: string) => {
  try {
    const snippet = matchPattern(content);

    if (!snippet) {
      return 'Title not available';
    }
    return snippet.length > 25 ? `${snippet.substring(0, 25)}...` : snippet;
  } catch (error) {
    console.error('Error extracting chat snippet:', error);
    return 'Title not available';
  }
};

export const isChatsFromNotes = (note: NoteDetails) =>
  note.content.startsWith('# Chat');
