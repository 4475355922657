import { ChatMessageSenderEnum } from '@zarn/vendor/dist/search';

import {
  ChatContext,
  ChatMessageElement,
  DocsChatContext,
  DocsIdsChatContext,
} from 'api/chatApi/chatApi.types';
import {
  BotType,
  BotTypeMessage,
  BotTypeMessageWithMessage,
  DefaultBotTypeMessage,
  SummaryBotTypeMessage,
  TagOnboardingBotTypeMessage,
} from 'api/tenantSettingsApi/tenantSettingsApi.types';
import { isBotTypeMessageWithMessage } from 'api/tenantSettingsApi/tenantSettingsApi.utils';
import { RetrievalUnitEnum } from 'common/enums';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

import {
  Conversation,
  ConversationPayload,
  PayloadWithDocsIds,
  PayloadWithHits,
} from '../Chat.types';

const mapConversationMessage = (
  message: BotTypeMessageWithMessage
): ChatMessageElement => {
  return {
    content: message.message,
    sender: ChatMessageSenderEnum.User,
    ...(message.image && message.image.includes('data:image/png;base64')
      ? { image: message.image }
      : {}),
    ...(message.documentsIds
      ? {
          context: {
            docsIds: message.documentsIds,
            retrievalUnit: RetrievalUnitEnum.Document,
          },
        }
      : {}),
  };
};

const addMessageToConversation = <T extends object = {}>(
  message: DefaultBotTypeMessage | TagOnboardingBotTypeMessage,
  conversation: Conversation<T> | null,
  botType?: string,
  payload?: ChatContext | PayloadWithDocsIds | PayloadWithHits
): Conversation<T> => ({
  botParams: conversation?.botParams || message.botParams || {},
  botType: conversation?.botType || botType || 'default',
  messages: isBotTypeMessageWithMessage(message)
    ? [...(conversation?.messages || []), mapConversationMessage(message)]
    : [],
  payload: conversation?.payload ?? (payload as any),
});

const createNewSummaryConversation = <T extends object = {}>(
  message: SummaryBotTypeMessage,
  payload?: T
): Conversation<T> => ({
  botType: 'summary' as BotType,
  messages: [
    {
      content: message.message,
      sender: ChatMessageSenderEnum.User,
    },
  ],
  payload,
});

const createEmptyConversation = <T extends object = {}>(
  botType: BotType,
  payload?: T
): Conversation<T> => ({
  botType,
  messages: [],
  payload,
});

export const buildChatConversation = <T extends object = {}>(
  message: BotTypeMessage,
  conversation: Conversation<T> | null,
  payload?: ConversationPayload
): Conversation<T> => {
  switch (message.botType) {
    case 'summary':
      return createNewSummaryConversation(
        message as SummaryBotTypeMessage,
        conversation?.payload
      );
    case 'quizbot':
      return createEmptyConversation(
        message.botType,
        (conversation?.payload ?? payload) as T
      );
    default:
    case 'chat_with_tag':
    case 'chat_with_pdf':
    case 'chat_with_qa_widget':
    case 'k_answer_table':
      return addMessageToConversation(
        message,
        conversation,
        message.botType,
        payload && (isPayloadWithDocsIds(payload) || isPayloadWithHits(payload))
          ? payload
          : conversation?.payload
      );
  }
};

export const getContext = (
  docsIds: string[] | undefined
): ChatContext | undefined => {
  return docsIds && docsIds.length
    ? {
        fromDocIds: {
          contextFields: [],
          docId: docsIds,
          retrievalUnit: RetrievalUnitEnum.Document,
        },
      }
    : undefined;
};

export const isPayloadWithDocsIds = (
  payload: ConversationPayload
): payload is PayloadWithDocsIds => 'docsIds' in payload;

export const isPayloadWithHits = (
  payload: ConversationPayload
): payload is PayloadWithHits => 'hits' in payload;

export const isChatContextWithDocsIds = (
  context: ChatContext
): context is DocsIdsChatContext => 'fromDocIds' in context;

export const isChatContextWithDocs = (
  context: ChatContext
): context is DocsChatContext => 'fromDocs' in context;

export const isContextPayloadWithHits = (
  context: any
): context is {
  payload?: { hits?: RetrievalUnitData[] };
} =>
  'payload' in context &&
  context.payload &&
  'hits' in context.payload &&
  context.payload.hits;
