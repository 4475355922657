import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<
  Theme,
  {
    popoverMaxHeight: number;
    popoverWidth: string | number;
  }
>((theme: Theme) => ({
  collapseEntered: {
    '& > .MuiCollapse-wrapper': {
      height: '100%',
    },
  },
  popper: {
    position: 'absolute',
    zIndex: -1,
  },
  popperOpen: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: `calc(100vh - 200px)`,
    padding: theme.spacing(1, 2),
    width: 300,
  },
  wrapperInner: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
