import React, { FC, useMemo } from 'react';

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import ReactMarkdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { htmlToMarkdown } from 'common/utils/markdown';

import { LinkBlank } from './LinkBlank';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& > *:first-child': {
      marginTop: 0,
    },
    '& > *:last-child': {
      marginBottom: 0,
    },
    '& b': {
      backgroundColor: 'yellow',
      fontWeight: 'normal',
    },
    '& img': {
      maxWidth: '100%',
    },
    '& pre': {
      '& > code': {
        padding: 0,
      },
    },
    '& table': {
      border: `1px solid ${theme.palette.divider}`,
      borderCollapse: 'collapse',
      borderRadius: '15px',
      color: theme.palette.text.primary,
      overflow: 'hidden',
    },
    '& td, & th': {
      border: `1px solid ${theme.palette.divider}`,
      padding: '6px 13px',
    },
  },
}));

interface Props {
  className?: string;
  components?: Components;
  content: string;
}

export const SimpleMarkdownText: FC<Props> = ({
  className,
  components = {},
  content,
  ...rest
}) => {
  const classes = useStyles();
  const markdown = useMemo(() => htmlToMarkdown(content), [content]);

  return (
    <ReactMarkdown
      className={clsx(classes.root, className)}
      components={{
        // @ts-ignore
        a: LinkBlank,
        ...components,
      }}
      remarkPlugins={[remarkGfm]}
      {...rest}
    >
      {markdown}
    </ReactMarkdown>
  );
};
