import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { WhiteLabelFooterSettings } from '@zarn/vendor/dist/search';

import { useHiddenForPages } from 'common/hooks/useHiddenForPages';
import { useTenantSettings } from 'common/hooks/useTenantSettings';

import { TrackEventName } from '../TrackedActions/withTrackedAction.interface';

export const useFooter = () => {
  const { t } = useTranslation('common');
  const isHidden = useHiddenForPages(['/reset-password', '/create-password']);
  const { tenantSettings } = useTenantSettings();
  const year = new Date().getFullYear();
  const copyright = t('footer.copyright', { year });

  const footer: Required<WhiteLabelFooterSettings> = useMemo(
    () => ({
      copyright:
        tenantSettings?.whitelabel?.footer?.copyright?.replace(
          '{{year}}',
          `${year}`
        ) ?? copyright,
      icons: tenantSettings?.whitelabel?.footer?.icons ?? [
        {
          _target: '_blank',
          eventName: TrackEventName.BlueskySocialClicked,
          icon: '/assets/img/bluesky.svg',
          url: t('socialNetworks.bluesky.url'),
        },
        {
          _target: '_blank',
          eventName: TrackEventName.LinkedInSocialClicked,
          icon: '/assets/img/linkedin.png',
          url: t('socialNetworks.linkedin.url'),
        },
      ],
      links: tenantSettings?.whitelabel?.footer?.links ?? [
        {
          eventName: TrackEventName.AboutProductClicked,
          label: t('footer.nav.about'),
          url: '/product/about',
        },
        {
          eventName: TrackEventName.ProductFeaturesClicked,
          label: t('footer.nav.features'),
          url: '/product/features',
        },
        {
          eventName: TrackEventName.ProductReleaseNotesClicked,
          label: t('footer.nav.releaseNotes'),
          url: '/product/release-notes',
        },
      ],
    }),
    [
      copyright,
      t,
      tenantSettings?.whitelabel?.footer?.copyright,
      tenantSettings?.whitelabel?.footer?.icons,
      tenantSettings?.whitelabel?.footer?.links,
    ]
  );

  return { footer, isHidden };
};
