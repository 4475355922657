import { MutableRefObject, useCallback } from 'react';

import { captureException } from '@sentry/react';

import { useParsedHostname } from 'common/utils/useParsedHostname';
import { useNotesSearchParams } from 'containers/SavedNotes/NotesSearch/useNotesSearchParams';

import { LoadingsRecord } from '../ChatList/types';

import { getNotes } from './notes.utils';

export const useNotesList = (
  loadingsRef: MutableRefObject<LoadingsRecord>,
  onFetched: (notesAmount: number) => void,
  pageSize = 10
) => {
  const { payload } = useNotesSearchParams({ disableFilter: true });
  const { tenant } = useParsedHostname();

  const fetchNotesPage = useCallback(
    async (pageIndex: number) => {
      try {
        const promise = getNotes({
          ...payload,
          page: pageIndex,
          tenant,
        });
        loadingsRef.current[pageIndex] = promise;
        const page = await promise;
        loadingsRef.current[pageIndex] = page;

        onFetched(page.count);

        return page;
      } catch (error) {
        captureException(error);
        throw error;
      }
    },
    [loadingsRef, onFetched, payload, tenant]
  );

  const getNoteFromList = useCallback(
    async (index: number) => {
      const pageIndex = Math.floor(index / pageSize) + 1;
      const page = loadingsRef.current[pageIndex];

      if (!page) {
        loadingsRef.current[pageIndex] = fetchNotesPage(pageIndex);
      }

      if (page instanceof Promise) {
        const loadingPage = await page;
        return loadingPage.items[index % pageSize];
      }

      if (!page) {
        return null;
      }

      return page!.items[index % pageSize];
    },
    [fetchNotesPage, loadingsRef, pageSize]
  );

  return {
    fetchNotesPage,
    getNoteFromList,
  };
};
