import { useEffect, useRef } from 'react';

import { useLocationParams } from 'common/hooks/useLocationParams';

export const useOnNewChat = (callBack: () => void) => {
  const { noteId } = useLocationParams('noteId');
  const noteIdRef = useRef(noteId);

  useEffect(() => {
    if (!noteIdRef.current && noteId) {
      callBack();
    }
    noteIdRef.current = noteId;
  }, [noteId, callBack]);
};
