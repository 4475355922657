import { useEffect } from 'react';

import { BotTypeMessage } from 'api/tenantSettingsApi/tenantSettingsApi.types';
import { useLocationParams } from 'common/hooks/useLocationParams';
import { useNavigationWithState } from 'common/hooks/useNavigationWithState';

import { useChatMessages } from './useChatMessages';

export const useAutoSendingExplanationText = (
  onSendMessage: (botMessage: BotTypeMessage) => Promise<void>,
  clearConversation: () => void
) => {
  const { buildExplainImageMessage, buildExplainMessage } = useChatMessages();
  const { replace } = useNavigationWithState();
  const { explainText } = useLocationParams('explainText');
  const { noteId } = useLocationParams('noteId');

  useEffect(() => {
    if (explainText) {
      clearConversation();
      const isImageHighlight = explainText.includes('data:image/png;base64');
      const noteIdForURL = noteId ? `&noteId=${noteId}` : '';
      replace(`${location.pathname}?tab=chat${noteIdForURL}`);

      const message = isImageHighlight
        ? buildExplainImageMessage(explainText)
        : buildExplainMessage(explainText);

      void onSendMessage(message);
    }
  }, [
    buildExplainMessage,
    explainText,
    replace,
    onSendMessage,
    buildExplainImageMessage,
    noteId,
    clearConversation,
  ]);
};
