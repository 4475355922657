export const blobToBase64 = async (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();

      reader.onloadend = () => {
        const dataUrl = reader.result as string;
        const base64 = dataUrl.split(',')[1];

        resolve(base64);
      };

      reader.readAsDataURL(blob);
    } catch (error) {
      reject(error);
    }
  });
