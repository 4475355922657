import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  hidden: {
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  menuItem: {
    '& a': {
      fontSize: '12px',
    },
    '&:hover $hidden': {
      opacity: 1,
    },
    alignItems: 'center',
    display: 'flex',

    justifyContent: 'space-between',
  },
  readOnly: {
    opacity: 0.5,
  },
  selectedItem: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[1],
    fontWeight: 'bold',
  },
}));
