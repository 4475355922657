import {
  ChatContext,
  DocsChatContext,
  DocsIdsChatContext,
} from 'api/chatApi/chatApi.types';

import { ConversationPayload } from './Chat.types';

const createPayloadtWithDocIds = (context: DocsIdsChatContext) => {
  return {
    docsIds: context.fromDocIds?.docId,
    originalUrl: context.fromDocIds?.contextFields.join(', '),
    retrievalUnit: context.fromDocIds?.retrievalUnit,
  };
};

const createPayloadWithHits = (context: DocsChatContext) => {
  return {
    hits: context.fromDocs,
  };
};

export const transformContextToPayload = (
  context: ChatContext
): ConversationPayload => {
  if ('fromDocIds' in context) {
    return createPayloadtWithDocIds(context);
  } else if ('fromDocs' in context) {
    return createPayloadWithHits(context);
  }
  return {};
};

export const prepareUserMessage = (message: string) =>
  message.trim().replace('\n', '\n\n');
