import React, { FC, useRef } from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import { IconButton } from '@mui/material';

import { ACCEPTED_FILE_TYPES_STRING } from 'common/constants/acceptedFileTypes';

interface Props {
  onSelectFiles: (files: File[]) => void;
}

export const UploadFilesButton: FC<Props> = ({ onSelectFiles }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      onSelectFiles(Array.from(event.target.files));
    }
    // @ts-ignore
    fileInputRef.current!.value = null;
  };

  return (
    <IconButton size="small" onClick={handleClick}>
      <AttachFileIcon />
      <input
        accept={ACCEPTED_FILE_TYPES_STRING}
        ref={fileInputRef}
        style={{ display: 'none' }}
        type="file"
        multiple
        onChange={handleFileChange}
      />
    </IconButton>
  );
};
