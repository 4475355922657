import React from 'react';

import { Box, Tooltip } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useChatPage } from 'containers/Chat/hooks/useChatPage';

import { ChatListButton } from './ChatListButton/ChatListButton';
import { NewChatButton } from './NewChatButton/NewChatButton';
import { useStyles } from './styles';

interface ChatNavButtonProps {
  minified?: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const ChatNavButton = React.forwardRef(
  (
    { minified, open, setOpen }: ChatNavButtonProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { t } = useTranslation('pages');
    const { isChatPage, openChatPage } = useChatPage();
    const classes = useStyles();

    return (
      <Tooltip
        classes={{ popper: clsx(!minified && classes.tooltipPopper) }}
        data-testid="ChatNavButton"
        placement="right"
        title={t('chat.navTitle')}
      >
        <Box
          className={clsx(classes.wrapper, isChatPage && classes.wrapperActive)}
          ref={ref}
        >
          <ChatListButton
            activeSection={isChatPage}
            minified={minified}
            open={open}
            setOpen={setOpen}
          />

          {!minified && <NewChatButton onClick={openChatPage} />}
        </Box>
      </Tooltip>
    );
  }
);
