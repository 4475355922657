import React from 'react';

import { ClickAwayListener, Collapse, Popover } from '@mui/material';
import clsx from 'clsx';

import { useAuth } from '../../Auth/hooks/useAuth';

import { ChatsNotesList } from './ChatList/ChatsNotesList';
import { ChatNavButton } from './ChatNavButton/ChatNavButton';
import { ChatScrollProvider } from './context/ChatScrollContext';
import { useChatNav } from './hooks/useChatNav';
import { useStyles } from './styles';

type Props = {
  minified?: boolean;
  popoverWidth?: string | number;
};

export const ChatNav = ({ minified, popoverWidth = 300 }: Props) => {
  const { isAuthenticated } = useAuth();
  const {
    handleClickAway,
    handleClose,
    itemRef,
    open,
    popoverMaxHeight,
    setOpen,
  } = useChatNav({
    minified,
  });

  const classes = useStyles({
    popoverMaxHeight,
    popoverWidth,
  });

  return (
    <ChatScrollProvider>
      <ClickAwayListener onClickAway={handleClickAway}>
        <>
          <ChatNavButton
            minified={minified}
            open={open}
            ref={itemRef}
            setOpen={setOpen}
          />

          {minified ? (
            <Popover
              anchorEl={itemRef.current}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              className={clsx(open ? classes.popperOpen : classes.popper)}
              open={open}
              disableScrollLock
              onClose={handleClose}
            >
              {isAuthenticated && open && <ChatsNotesList />}
            </Popover>
          ) : (
            <Collapse
              classes={{
                entered: classes.collapseEntered,
                wrapperInner: classes.wrapperInner,
              }}
              data-testid="drawerSectionCollapse"
              in={open}
              mountOnEnter
            >
              {isAuthenticated && open && <ChatsNotesList />}
            </Collapse>
          )}
        </>
      </ClickAwayListener>
    </ChatScrollProvider>
  );
};
