import React, { FC, useCallback, useMemo } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import NotesIcon from '@mui/icons-material/Notes';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useTranslation } from 'react-i18next';

import { NoteObjectType } from '@zarn/vendor/dist/saved-results';

import { openMarkdownAsPdf } from 'common/components/Markdown/exportToPdf';
import {
  MoreActionItem,
  MoreActions,
} from 'common/components/MoreActions/MoreActions';
import { useSaveAsNote } from 'common/hooks/useSaveAsNote';

interface Props {
  content?: string;
}

export const MessageActions: FC<Props> = ({ content }) => {
  const { t } = useTranslation(['common']);

  const { saveAsNote } = useSaveAsNote({
    noteContent: content ?? null,
    noteObjectType: NoteObjectType.Free,
  });

  const saveMessageAsNote = useCallback(async () => {
    await saveAsNote();
  }, [saveAsNote]);

  const copyMessage = useCallback(() => {
    if (content) {
      navigator.clipboard.writeText(content);
    }
  }, [content]);

  const moreActionItems: MoreActionItem[] = useMemo(() => {
    const actionItems: MoreActionItem[] = [];

    if (saveMessageAsNote) {
      actionItems.push({
        Icon: NotesIcon,
        label: 'Save as note',
        onClick: saveMessageAsNote,
      });
    }

    if (copyMessage) {
      actionItems.push({
        Icon: ContentCopyIcon,
        label: 'Copy',
        onClick: copyMessage,
      });
    }

    if (content) {
      actionItems.push({
        Icon: PictureAsPdfIcon,
        label: t('common:notes.actions.exportToPdf'),
        onClick: () => openMarkdownAsPdf(content, ''),
      });
    }

    return actionItems;
  }, [saveMessageAsNote, copyMessage, content, t]);

  return (
    <MoreActions
      actions={moreActionItems}
      size="small"
      testId="noteActions"
      title={'Message actions'}
    />
  );
};
