import React, { FC, useState } from 'react';

import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton, ListItemSecondaryAction } from '@mui/material';
import clsx from 'clsx';
import { useLocation } from 'react-router';

import { PageDrawerMenuItem } from 'common/components/PageDrawer/PageDrawerMenuItem/PageDrawerMenuItem';
import { useNavigationWithState } from 'common/hooks/useNavigationWithState';
import { useChatPage } from 'containers/Chat/hooks/useChatPage';

import { useDeleteChat } from '../../hooks/useDeleteChat';
import { extractChatSnippet } from '../../utils/ChatNav.utils';
import { ChatSettingsPopover } from '../ChatSettingsPopover';

import { useStyles } from './styles';

interface Props {
  content: string;
  id: number;
  isReadOnly: boolean;
  onDelete: () => void;
}

export const ChatListElement: FC<Props> = ({
  content,
  id,
  isReadOnly,
  onDelete,
}) => {
  const classes = useStyles();
  const { push } = useNavigationWithState();
  const { chatNoteId } = useChatPage();
  const location = useLocation();
  const { deleteChat } = useDeleteChat();

  const [popover, setPopover] = useState<HTMLButtonElement | null>(null);

  const isChatPage = () => {
    const pathname = location.pathname;
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get('tab');

    return pathname === '/chat' && !tab;
  };

  const isCurrentChat = isChatPage() && chatNoteId === String(id);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopover(event.currentTarget);
  };

  const handleClose = () => {
    setPopover(null);
  };

  const handleDelete = () => {
    deleteChat(id);
    handleClose();
    if (isCurrentChat) {
      push('/chat');
    }
    onDelete();
  };

  return (
    <PageDrawerMenuItem
      className={clsx(
        classes.menuItem,
        isCurrentChat ? classes.selectedItem : '',
        isReadOnly ? classes.readOnly : ''
      )}
      key={id}
      title={extractChatSnippet(content)}
      to={`/chat?noteId=${id}`}
    >
      <ListItemSecondaryAction>
        {!isReadOnly && (
          <IconButton
            aria-label="edit tag"
            className={!isCurrentChat ? classes.hidden : undefined}
            edge="end"
            size="small"
            onClick={handleOpen}
          >
            <SettingsIcon fontSize="small" />
          </IconButton>
        )}
        {isReadOnly && (
          <IconButton edge="end" size="small" disabled>
            <GroupIcon fontSize="small" />
          </IconButton>
        )}
      </ListItemSecondaryAction>
      <ChatSettingsPopover
        popover={popover}
        onClose={handleClose}
        onDelete={handleDelete}
      />
    </PageDrawerMenuItem>
  );
};
