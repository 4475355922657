export const ACCEPTED_FILE_TYPES = {
  'application/msword': ['.doc'],
  'application/pdf': ['.pdf'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.oasis.opendocument.graphics': ['.odg'],
  'application/vnd.oasis.opendocument.presentation': ['.odp'],
  'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
  'application/vnd.oasis.opendocument.text': ['.odt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [
    '.pptx',
  ],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
  'application/vnd.visio': ['.vsd'],
  'text/html': ['.html'],
  'text/markdown': ['.md'],
  'text/plain': ['.txt'],
};

export const ACCEPTED_FILE_TYPES_STRING = Object.entries(ACCEPTED_FILE_TYPES)
  .flatMap(([type, extensions]) => [type, ...extensions])
  .join(',');
