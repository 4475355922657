import React, { FC } from 'react';

import { Box, useTheme } from '@mui/material';
import { AppTheme } from 'app/theme';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import OpenAiIcon from 'common/components/CustomIcons/OpenAiIcon';
import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { useLastOpenedChat } from 'common/hooks/useLastOpenedChat';
import { useNavigationWithState } from 'common/hooks/useNavigationWithState';

import { useChatScroll } from '../../context/ChatScrollContext';

import { useStyles } from './styles';
import { ToggleChatListIconButton } from './ToggleChatListIconButton';

interface BaseProps {
  activeSection: boolean;
  minified?: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
}

type Props = WithTrackedActionWrappedProps<BaseProps>;

const ChatListButtonComponent: FC<Props> = ({
  activeSection,
  minified,
  onAction,
  open,
  setOpen,
}) => {
  const theme = useTheme<AppTheme>();
  const classes = useStyles();
  const { push } = useNavigationWithState();
  const { t } = useTranslation('pages');
  const { getLastOpenedChatId } = useLastOpenedChat();
  const { triggerScroll } = useChatScroll();

  const handleToggle = () => {
    onAction();
    setOpen(!open);
  };

  const handleOpenPrevChatPage = () => {
    const lastOpenedChatId = getLastOpenedChatId();
    if (lastOpenedChatId) {
      triggerScroll();
    }
    push(`/chat${lastOpenedChatId ? `?noteId=${lastOpenedChatId}` : ''}`);
  };

  return (
    <>
      <ToggleChatListIconButton handleToggle={handleToggle} open={open} />

      <button
        aria-label={t('chat.navTitle')}
        className={classes.toggleButton}
        type="button"
        onClick={handleOpenPrevChatPage}
      >
        <Box className={classes.icon}>
          <OpenAiIcon
            style={{
              fill: activeSection
                ? theme.palette.sideDrawer.icons.highlight
                : theme.palette.sideDrawer.icons.defaultColor,
            }}
          />
        </Box>
        {!minified ? (
          <Box
            className={clsx(classes.text, activeSection && classes.textActive)}
          >
            {t('chat.navTitle')}
          </Box>
        ) : null}
      </button>
    </>
  );
};

export const ChatListButton = withTrackedAction<
  BaseProps & WithTrackedActionProps
>(ChatListButtonComponent, TrackEventName.ChatListButtonClicked);
