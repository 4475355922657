import { MutableRefObject, useEffect, useState } from 'react';

import { NoteDetails } from 'api/notesApi/notesApi.types';
import { Nullable } from 'common/utils/assert';

import { useChatScroll } from '../../../context/ChatScrollContext';
import { useNotesList } from '../../../hooks/useNotesList';
import { LoadingsRecord } from '../../types';

interface UseNoteItemProps {
  index: number;
  isVisible: boolean;
  loadingsRef: MutableRefObject<LoadingsRecord>;
  onPageFetched: (notesAmount: number) => void;
  ref: React.RefObject<HTMLDivElement>;
}

export const useChatNoteItem = ({
  index,
  isVisible,
  loadingsRef,
  onPageFetched,
  ref,
}: UseNoteItemProps) => {
  const searchParams = new URLSearchParams(location.search);
  const currentNoteId = searchParams.get('noteId');
  const { getNoteFromList } = useNotesList(loadingsRef, onPageFetched);
  const [note, setNote] =
    useState<Nullable<NoteDetails | { isLoading: boolean }>>(null);
  const { resetScroll, shouldScroll } = useChatScroll();

  useEffect(() => {
    (async () => {
      if (!isVisible || note) {
        return;
      }
      setNote({ isLoading: true });
      setNote(await getNoteFromList(index));
    })();
  }, [getNoteFromList, index, isVisible, note]);

  useEffect(() => {
    if (
      note &&
      !('isLoading' in note) &&
      currentNoteId &&
      Number(currentNoteId) === note.id &&
      (shouldScroll || currentNoteId)
    ) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      if (shouldScroll) {
        resetScroll();
      }
    }
  }, [note, currentNoteId, shouldScroll, resetScroll, ref]);

  return { note };
};
