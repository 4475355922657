import React, { FC, ReactNode } from 'react';

import { Evidence } from 'api/chatApi/chatApi.types';
import { NoteDetails } from 'api/notesApi/notesApi.types';
import { ErrorBox } from 'common/components/ErrorBox/ErrorBox';
import { useAssertTenantSettings } from 'common/hooks/useAssertTenantSettings';
import { useLocationParams } from 'common/hooks/useLocationParams';
import { ChunkHighlight } from 'common/interfaces/ChunkHighlight.interfaces';
import { Optional } from 'common/utils/assert';
import { isReadOnlyPermission } from 'common/utils/permissions';

import { Chat } from './Chat';
import { ChatOptions } from './Chat.props';
import { Conversation } from './Chat.types';
import { useChatNote } from './hooks/useChatNote';

interface Props {
  createNote: (content: string) => Promise<NoteDetails>;
  isDisabled?: boolean;
  isEvidenceDialog?: boolean;
  onEvidenceChunkClick?: (
    chunks: ChunkHighlight[],
    docIdOrChunkId: Optional<string>
  ) => void;
  onEvidenceItemClick?: (evidence: Evidence) => void;
  options: ChatOptions;
  predefinedConversation?: Conversation | null;
  renderContextAction?: (conversation?: Conversation) => ReactNode;
}

export const ChatSource: FC<Props> = ({
  createNote,
  isDisabled,
  isEvidenceDialog,
  onEvidenceChunkClick,
  onEvidenceItemClick,
  options,
  predefinedConversation,
  renderContextAction,
}) => {
  const { noteId } = useLocationParams('noteId');
  const { tenantSettings } = useAssertTenantSettings();

  const { error, isLoading, isSaving, note, trySaveNote } = useChatNote(
    noteId,
    createNote
  );

  const isReadOnly =
    isDisabled || (!!note && isReadOnlyPermission(note.permission));

  if (!tenantSettings.chat) {
    return <ErrorBox error={new Error('Chat is not enabled')} />;
  }

  if (error) {
    return <ErrorBox error={error} />;
  }

  return (
    <Chat
      content={note?.content ?? null}
      disabled={!options.chatConfig || isReadOnly}
      isEvidenceDialog={isEvidenceDialog}
      isLoading={isLoading}
      isSaving={isSaving}
      options={options}
      predefinedConversation={predefinedConversation}
      renderContextAction={renderContextAction}
      onEvidenceChunkClick={onEvidenceChunkClick}
      onEvidenceItemClick={onEvidenceItemClick}
      onSave={trySaveNote}
    />
  );
};
