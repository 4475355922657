import { useIsInsidePdfViewer } from 'common/hooks/useIsInsidePdfViewer';
import { clearLabelFromBrackets } from 'common/utils/strings.utils';
import { useDocDetails } from 'containers/Docs/hooks/useDocDetails';

import { parseHref } from './helpers';
import { useDocumentLink } from './useDocumentLink';

export const useReferenceLink = (label: string, href: string) => {
  const text = clearLabelFromBrackets(label);

  const { chunkId, docId } = parseHref(href);
  const { isPdfOpen } = useIsInsidePdfViewer({ docId });

  const { data: docData, isLoading: isDocLoading } = useDocDetails({
    docId,
    options: {
      enabled: !!docId,
    },
    propertyName: 'id',
  });

  const link = useDocumentLink(href, docId, docData, chunkId, isPdfOpen);

  const chipsProps = link
    ? {
        clickable: true,
        component: 'a',
        href: link,
        rel: 'noopener noreferrer',
        target: '_blank',
      }
    : {
        clickable: false,
      };

  const tooltipProps = isPdfOpen ? {} : { data: docData, isDocLoading };

  return { chipsProps, link, text, tooltipProps };
};
