import React from 'react';

import { Button, ButtonProps } from '@mui/material';
import Link from '@mui/material/Link';
import { NavLink, NavLinkProps } from 'react-router-dom';

import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';

type Props = ButtonProps & NavLinkProps & { href?: string };

const TrackedNavLinkButton = ({
  children,
  href,
  onAction,
  onClick,
  to,
  ...rest
}: WithTrackedActionWrappedProps<Props>) => {
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onAction();
    if (!onClick) return;

    onClick(event);
  };

  if (href) {
    return (
      <Link href={href} rel="noreferrer" target="_blank" title={href}>
        <Button {...rest} onClick={handleClick}>
          {children}
        </Button>
      </Link>
    );
  }

  return (
    <Button component={NavLink} to={to} {...rest} onClick={handleClick}>
      {children}
    </Button>
  );
};

export default withTrackedAction<Props & WithTrackedActionProps>(
  TrackedNavLinkButton
);
