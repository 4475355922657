import React, { createContext, useContext, useState } from 'react';

interface ChatScrollContextType {
  resetScroll: () => void;
  shouldScroll: boolean;
  triggerScroll: () => void;
}

const ChatScrollContext = createContext<ChatScrollContextType>({
  resetScroll: () => {},
  shouldScroll: false,
  triggerScroll: () => {},
});

export const ChatScrollProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [shouldScroll, setShouldScroll] = useState(false);

  const triggerScroll = () => setShouldScroll(true);
  const resetScroll = () => setShouldScroll(false);

  return (
    <ChatScrollContext.Provider
      value={{ resetScroll, shouldScroll, triggerScroll }}
    >
      {children}
    </ChatScrollContext.Provider>
  );
};

export const useChatScroll = () => useContext(ChatScrollContext);
