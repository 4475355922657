import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { AnnotationHighlight } from 'api/notesApi/notesApi.types';
import {
  BotConfiguration,
  ChatConfig,
} from 'api/tenantSettingsApi/tenantSettingsApi.types';
import { SearchEngineEnum } from 'common/enums';
import { PartialRecord } from 'common/utils/types.helpers';
import { transformContextToPayload } from 'containers/Chat/Chat.utils';
import { ChatSource } from 'containers/Chat/ChatSource';
import DocStatus from 'containers/RetrievalUnit/DocStatus/DocStatus';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

import { useCreateDocumentNote } from '../hooks/useCreateDocumentNote';
import { useOnEvidenceChunkClick } from '../hooks/useOnEvidenceChunkClick';
import { usePdfChatContext } from '../hooks/usePdfChatContext';

interface Props {
  botTypesConfiguration: PartialRecord<string, BotConfiguration>;
  chatConfig: ChatConfig;
  docData: RetrievalUnitData;
  docId: string;
  onAddHighlights: (highlights: AnnotationHighlight[]) => void;
  otherDocumentEvidenceChunkClick: (chunkId: string | null) => void;
  scrollTo: (top: number) => void;
  searchEngine?: SearchEngineEnum;
}

export const ChatOfPdfViewer: FC<Props> = ({
  botTypesConfiguration,
  chatConfig,
  docData,
  docId,
  onAddHighlights,
  otherDocumentEvidenceChunkClick,
  scrollTo,
  searchEngine,
}) => {
  const { t } = useTranslation('common');

  const { createDocumentNote } = useCreateDocumentNote(docId);
  const { onEvidenceChunkClick } = useOnEvidenceChunkClick({
    docId,
    onAddHighlights,
    otherDocumentEvidenceChunkClick,
    scrollTo,
  });

  const { context } = usePdfChatContext({ docData, docId, searchEngine });

  const greetings =
    docData.status && docData.status.title !== 'SUCCESS' ? (
      <DocStatus status={docData.status} sx={{ ml: 1, mr: 1 }} />
    ) : undefined;

  const isDisabled = docData.status && docData.status.title !== 'SUCCESS';

  return (
    <ChatSource
      createNote={createDocumentNote}
      isDisabled={isDisabled}
      options={{
        botTypesConfiguration,
        chatConfig,
        context,
        greetings,
        payload: context && transformContextToPayload(context),
        placeholder: t('chat.message.input.placeholderDocument'),
      }}
      onEvidenceChunkClick={onEvidenceChunkClick}
    />
  );
};
