import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  tooltipPopper: {
    display: 'none',
  },
  wrapper: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
    },
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(0.5, 0, 0.5, 0),
    top: 0,
    zIndex: 1,
  },
  wrapperActive: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[1],
    fontWeight: 'bold',
  },
}));
