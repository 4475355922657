import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

import { getChunkId, getDocumentId } from '../utils';

export const isExternalDoc = (docData?: RetrievalUnitData | null) =>
  docData?.resources?.some(
    (resource) => resource.resourceType === 'external_doc_id'
  );

export const hasPdf = (docData?: RetrievalUnitData | null) =>
  docData?.resources?.some((resource) => resource.resourceType === 'pdf_url');

export const parseHref = (href: string) => {
  if (href.includes('documents/chunk')) {
    const urlParams = new URLSearchParams(href);
    const chunkId = urlParams.get('property_values');
    return {
      chunkId,
      docId: chunkId?.split('_')[0] + '_0',
    };
  }

  if (href.includes('documents/document')) {
    const urlParams = new URLSearchParams(href);
    return {
      docId: urlParams.get('property_values'),
    };
  }

  return {
    chunkId: getChunkId(href),
    docId: getDocumentId(href),
  };
};
