import { useCallback } from 'react';

import { NoteObjectType } from '@zarn/vendor/dist/saved-results';

import { createNote } from 'api/notesApi/notesApi';
import { deserializeSharing } from 'api/notesApi/notesApi.utils';
import { useQuerySearchParams } from 'common/hooks/useQuerySearchParams';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { useAuth } from 'containers/Auth/hooks/useAuth';

export const useCreateDocumentNote = (docId: string) => {
  const { tenant } = useParsedHostname();
  const { setParams } = useQuerySearchParams();
  const { me } = useAuth();
  const orgId = me?.organization?.userRoleId;

  const createDocumentNote = useCallback(
    async (content: string) => {
      const { data: note } = await createNote({
        annotationHighlight: null,
        content,
        objectId: docId,
        objectType: NoteObjectType.Document,
        sharing: deserializeSharing(orgId),
        tenant,
      });
      setParams({ noteId: `${note.id}` });
      return note;
    },
    [docId, orgId, setParams, tenant]
  );

  return { createDocumentNote };
};
