import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { useParsedHostname } from 'common/utils/useParsedHostname';
import { NotesFilterVisibility } from 'containers/NotesFilter/NotesFilter.types';
import {
  deserializeNotesSortBySearchParam,
  serializeNotesFilterVisibility,
} from 'containers/NotesFilter/notesFilter.utils';

import { NotesResultsPayload } from './NotesSearch.interface';
import { NotesSearchUrlParamsEnum } from './NotesSearchUrlParams.enum';

interface Props {
  disableFilter?: boolean;
}

export interface UseNotesSearchParamsReturn {
  payload: NotesResultsPayload;
}

export const useNotesSearchParams = ({
  disableFilter,
}: Props): UseNotesSearchParamsReturn => {
  const location = useLocation();
  const { tenant } = useParsedHostname();

  const payload = useMemo((): NotesResultsPayload => {
    const searchParams = new URLSearchParams(location.search);

    return {
      createdByMe: serializeNotesFilterVisibility(
        searchParams.get(
          disableFilter
            ? NotesFilterVisibility.OnlyMyNotes
            : NotesSearchUrlParamsEnum.Visibility
        )
      ),
      page: 1,
      pageSize: 10,
      q: disableFilter
        ? ''
        : searchParams.get(NotesSearchUrlParamsEnum.Query) ?? '',
      sortBy: deserializeNotesSortBySearchParam(
        searchParams.get(
          disableFilter ? 'date_created:desc' : NotesSearchUrlParamsEnum.SortBy
        )
      ),
      tenant,
    };
  }, [location.search, disableFilter, tenant]);

  return {
    payload,
  };
};
