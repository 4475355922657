import { useCallback, useEffect, useMemo } from 'react';

import { captureException } from '@sentry/react';
import { useQuery } from 'react-query';

import { retrieveTenantSettings } from 'api/tenantSettingsApi/tenantSettingsApi';
import {
  BotType,
  TenantSettings,
} from 'api/tenantSettingsApi/tenantSettingsApi.types';
import { BaseError } from 'common/models/Error.interface';
import { deserializeAxiosError } from 'common/utils/error';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { useAuthStatusChange } from 'containers/Auth/hooks/useAuthStatusChange';

import { QUERY_OPTIONS } from '../constants/query-options';
import { isExist } from '../utils/assert';
import { saveDisplayConfigurationToLocalStorage } from '../utils/displayConfiguration.utils';
import { setFavicon } from '../utils/favicon';
import { useIndexCluster } from '../utils/useIndexCluster';

interface TenantSettingsRequest {
  indexCluster?: string | null;
  tenant: string;
}

const tenantSettingsQueryKeys = {
  all: ['tenantSettings'] as const,
  detail: (tenantSettingsRequest: TenantSettingsRequest) =>
    [
      ...tenantSettingsQueryKeys.details(),
      ...Object.values(tenantSettingsRequest),
    ] as const,
  details: () => [...tenantSettingsQueryKeys.all, 'details'] as const,
};

export const useTenantSettings = () => {
  const { tenant } = useParsedHostname();
  const { indexCluster } = useIndexCluster();

  const query = useQuery<TenantSettings, BaseError>(
    tenantSettingsQueryKeys.detail({ indexCluster, tenant }),
    async () => {
      try {
        const { data } = await retrieveTenantSettings(tenant, indexCluster);

        saveDisplayConfigurationToLocalStorage(
          data.displayConfiguration ?? null
        );

        if (data?.whitelabel?.favicon) {
          setFavicon(data.whitelabel.favicon);
        }

        return data;
      } catch (error) {
        captureException(error);
        throw deserializeAxiosError(error);
      }
    },
    {
      ...QUERY_OPTIONS,
      staleTime: Infinity,
    }
  );

  const tenantSettings: TenantSettings | null = useMemo(
    () => query.data ?? null,
    [query.data]
  );

  const { authStatusChange } = useAuthStatusChange();

  useEffect(() => {
    void query.refetch();
    // Trigger refetch on login/logout
  }, [authStatusChange]);

  const assertTenantSettings = useCallback(() => {
    if (!tenantSettings) {
      throw new Error('Tenant settings not loaded');
    }
    return tenantSettings;
  }, [tenantSettings]);

  const isSomeIndexAvailable = useMemo(
    () =>
      !!tenantSettings?.tabs &&
      Object.values(tenantSettings.tabs).some(({ enabled }) => enabled),
    [tenantSettings?.tabs]
  );

  const searchDynamicsFilters = useMemo(
    () => (tenantSettings ? tenantSettings.searchFiltersDynamic : null),
    [tenantSettings]
  );

  const getChatBotIdentifier = useCallback(
    (botType: BotType) => {
      if (!tenantSettings?.chat?.botTypesConfiguration) {
        return null;
      }
      const config = Object.values(tenantSettings.chat.botTypesConfiguration)
        .filter(isExist)
        .find(({ tenantBotType }) => tenantBotType === botType);
      if (!config) {
        return null;
      }
      return config.identifier;
    },
    [tenantSettings?.chat?.botTypesConfiguration]
  );

  return {
    ...query,
    assertTenantSettings,
    getChatBotIdentifier,
    isSomeIndexAvailable,
    searchDynamicsFilters,
    tenantSettings,
  };
};
