import { BotConfiguration } from 'api/tenantSettingsApi/tenantSettingsApi.types';

export const LS_CUSTOM_BOTS_KEY = 'ZA_CUSTOM_BOTS';
export const LS_VISIBLE_BOTS_KEY = 'ZA_VISIBLE_BOTS';

export const saveCustomBotsToLocalStorage = (
  customBots: Record<string, BotConfiguration>
): void => {
  localStorage.setItem(LS_CUSTOM_BOTS_KEY, JSON.stringify(customBots));
};

export const loadCustomBotsFromLocalStorage = (): Record<
  string,
  BotConfiguration
> => {
  try {
    const storedCustomBots = localStorage.getItem(LS_CUSTOM_BOTS_KEY);
    if (!storedCustomBots) {
      return {};
    }
    return JSON.parse(storedCustomBots);
  } catch (e) {
    console.error('Error loading custom bots from localStorage:', e);
    return {};
  }
};

export const saveVisibleBotsToLocalStorage = (
  visibleBots: Set<string>
): void => {
  localStorage.setItem(
    LS_VISIBLE_BOTS_KEY,
    JSON.stringify(Array.from(visibleBots))
  );
};

export const loadVisibleBotsFromLocalStorage = (): Set<string> => {
  try {
    const storedVisibleBots = localStorage.getItem(LS_VISIBLE_BOTS_KEY);
    if (!storedVisibleBots) {
      return new Set();
    }
    return new Set(JSON.parse(storedVisibleBots));
  } catch (e) {
    console.error('Error loading visible bots from localStorage:', e);
    return new Set();
  }
};
