import React, { useMemo } from 'react';

import WorkIcon from '@mui/icons-material/Work';
import { List, useTheme } from '@mui/material';
import { AppTheme } from 'app/theme';
import { useTranslation } from 'react-i18next';

import { PageDrawerContent } from 'common/components/PageDrawer/PageDrawerContent';
import { PageDrawerMenuItem } from 'common/components/PageDrawer/PageDrawerMenuItem/PageDrawerMenuItem';
import { PageDrawerMenuSection } from 'common/components/PageDrawer/PageDrawerMenuSection';
import { useIsActivePage } from 'common/hooks/useIsActivePage';

type SidebarManagementSectionProps = {
  minified?: boolean;
};

const SidebarManagementSection = ({
  minified,
}: SidebarManagementSectionProps) => {
  const { t } = useTranslation('pages');

  const preferencesPages = useMemo(
    () => [
      {
        page: '/management/user',
        title: t('pages:userManagement.navTitle'),
      },
    ],
    [t]
  );

  const theme = useTheme<AppTheme>();

  const { isActive } = useIsActivePage('/management/user');

  return (
    <PageDrawerContent>
      <PageDrawerMenuSection
        icon={
          <WorkIcon
            sx={{
              color: isActive
                ? theme.palette.sideDrawer.icons.highlight
                : theme.palette.sideDrawer.icons.defaultColor,
            }}
          />
        }
        menuOpen={false}
        minified={minified}
        sectionPath="/management"
        sectionTitle={t('management.navTitle')}
      >
        <List disablePadding>
          {preferencesPages.map(({ page, title }) => (
            <PageDrawerMenuItem key={page} title={t(title)} to={page} />
          ))}
        </List>
      </PageDrawerMenuSection>
    </PageDrawerContent>
  );
};

export default SidebarManagementSection;
