import React, { FC } from 'react';

import { Skeleton } from '@mui/material';

import { NoteDetails } from 'api/notesApi/notesApi.types';
import { Nullable } from 'common/utils/assert';
import { isReadOnlyPermission } from 'common/utils/permissions';

import { isChatsFromNotes } from '../../utils/ChatNav.utils';

import { ChatListElement } from './ChatListElement';

interface Props {
  note: Nullable<NoteDetails | { isLoading: boolean }>;
  onDelete: () => void;
}

export const ChatNoteItemContent: FC<Props> = ({ note, onDelete }) => {
  if (!note || (note && 'isLoading' in note)) {
    return <Skeleton sx={{ fontSize: '1rem' }} variant="text" />;
  }

  const isReadOnly = isReadOnlyPermission(note.permission);

  if (isReadOnly || !isChatsFromNotes(note)) {
    return null;
  }

  return (
    <ChatListElement
      content={note.content}
      id={note.id}
      isReadOnly={isReadOnly}
      key={note.id}
      onDelete={onDelete}
    />
  );
};
