import React, { FC, useCallback, useRef, useState } from 'react';

import { Box } from '@mui/material';

import { useOnNewChat } from 'containers/Chat/hooks/useOnNewChat';

import { ChatNoteItem } from './ChatListElement/ChatNoteItem';
import { LoadingsRecord } from './types';

const DEFAULT_NOTES_AMOUNT = 10;

export const ChatsNotesList: FC = () => {
  const loadingsRef = useRef<LoadingsRecord>({});
  const [notesAmount, setNotesAmount] = useState(DEFAULT_NOTES_AMOUNT);

  const reload = useCallback(() => {
    loadingsRef.current = {};
    setNotesAmount(0);
    setTimeout(() => {
      setNotesAmount(10);
    }, DEFAULT_NOTES_AMOUNT);
  }, []);

  useOnNewChat(reload);

  return (
    <Box
      data-testid="ChatList"
      sx={{
        height: notesAmount - 10 > 10 ? 247 : notesAmount - 10 * 18,
        overflowX: 'auto',
        paddingLeft: '1rem',
        width: 247,
      }}
    >
      {new Array(notesAmount).fill(null).map((_, index) => (
        <ChatNoteItem
          index={index}
          key={index}
          loadingsRef={loadingsRef}
          onDelete={reload}
          onPageFetched={setNotesAmount}
        />
      ))}
    </Box>
  );
};
