import React from 'react';

import { Badge, BadgeProps, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  colorGrey: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.common.black,
  },
  colorWarning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
}));

type BadgeColor = BadgeProps['color'];
type StyledBadgeProps = Omit<BadgeProps, 'color'> & {
  color?: BadgeColor | 'grey';
};

export const StyledBadge = ({
  children,
  color,
  overlap = 'rectangular',
  ...rest
}: StyledBadgeProps) => {
  const classes = useStyles();

  const badgeClasses = clsx({
    [classes.colorWarning]: color === 'warning',
    [classes.colorGrey]: color === 'grey',
  });

  return (
    <Badge
      {...rest}
      classes={{ badge: badgeClasses }}
      color={color === 'grey' ? 'default' : (color as BadgeColor)}
      overlap={overlap}
    >
      {children}
    </Badge>
  );
};
