import { FileWithPath } from 'react-dropzone';

import { Nullable } from 'common/utils/assert';
import { PrivateDocFormValues } from 'containers/PrivateDocs/PrivateDocForm/PrivateDocForm.interface';

import { getRetrieveUserDocument, userDocumentCreate } from './privateDocsApi';

export const uploadFileOrGetDocument = async (
  values: PrivateDocFormValues,
  fileToUpload: Nullable<FileWithPath>,
  tenant: string,
  useOpenai?: boolean,
  indexId?: string
) => {
  try {
    return await userDocumentCreate(values, fileToUpload, tenant, useOpenai);
  } catch (error) {
    if (
      (error as any)?.response?.status === 409 &&
      (error as any)?.response?.data?.detail?.id
    ) {
      const documentId = (error as any)?.response?.data?.detail?.id;

      return await getRetrieveUserDocument({
        documentId,
        indexId,
        requesterUuid: '',
        tenant,
        userRoles: '',
        userTenants: '',
      });
    }
    throw error;
  }
};
