import { useEffect } from 'react';

import { BotTypeMessage } from 'api/tenantSettingsApi/tenantSettingsApi.types';
import { useLocationParams } from 'common/hooks/useLocationParams';
import { useNavigationWithState } from 'common/hooks/useNavigationWithState';

import { useChatMessages } from './useChatMessages';

export const useAutoSendingTagExplanation = (
  onSendMessage: (botMessage: BotTypeMessage) => Promise<void>,
  isDisabled: boolean
) => {
  const { replace } = useNavigationWithState();
  const { start } = useLocationParams('start');
  const { buildTagOnboardingMessage } = useChatMessages();

  useEffect(() => {
    if (!start || start !== 'empty-tag' || isDisabled) {
      return;
    }
    replace(`${location.pathname}?tab=chat`);
    void onSendMessage(buildTagOnboardingMessage());
  }, [buildTagOnboardingMessage, isDisabled, onSendMessage, replace, start]);
};
