import React from 'react';

import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { AppTheme } from 'app/theme';

import { Footer } from '../Footer/Footer';

const useStyles = makeStyles<AppTheme>((theme) => ({
  main: {
    background: theme.palette.mainContent.background,
    flexGrow: 1,
    marginTop: '64px',
    minHeight: '100vh',
    overflow: 'hidden',
    width: '100%',
  },
}));

type Props = {
  children: React.ReactNode;
};

export const PageMainContent = ({ children }: Props) => {
  const theme = useTheme<AppTheme>();
  const classes = useStyles({ theme });

  return (
    <main className={classes.main}>
      {children}
      <Footer />
    </main>
  );
};
