import React, { FC, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  FormControl,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import SubmitButton from '../Buttons/SubmitButton';
import { WithTrackedActionProps } from '../TrackedActions/withTrackedAction';

type Props = {
  additionalConfirmation?: boolean;
  children: React.ReactNode;
  isLoading?: boolean;
  isShowCancel?: boolean;
  onClose?: () => void;
  onSubmit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  open: boolean;
  setOpen: (state: boolean) => void;
} & DialogProps &
  WithTrackedActionProps;

export const ExtendedConfirmationDialog: FC<Props> = ({
  additionalConfirmation,
  children,
  eventName,
  eventProps,
  isLoading,
  isShowCancel = true,
  maxWidth = 'xs',
  onSubmit,
  open,
  setOpen,
  ...dialogProps
}) => {
  const { t } = useTranslation('common');
  const [confirm, setConfirm] = useState<boolean>(false);

  const toggleChecked = () => {
    setConfirm(!confirm);
  };

  const handleClose = () => {
    setOpen(false);
    dialogProps.onClose?.();
  };

  return (
    <Dialog
      data-testid="confirmationDialog"
      maxWidth={maxWidth}
      open={open}
      fullWidth
      onClose={handleClose}
      {...dialogProps}
    >
      <DialogContent>
        <DialogContentText component="div">{children}</DialogContentText>

        {additionalConfirmation && (
          <FormControl fullWidth>
            <FormControlLabel
              control={<Switch checked={confirm} onChange={toggleChecked} />}
              label={t('confirmationDialog.confirmActionLabel')}
              labelPlacement="start"
            />
          </FormControl>
        )}
      </DialogContent>

      <DialogActions>
        {isShowCancel && (
          <Button onClick={handleClose}>{t('buttons.cancel')}</Button>
        )}

        <SubmitButton
          disabled={(additionalConfirmation && !confirm) || isLoading}
          eventName={eventName}
          eventProps={eventProps}
          isSubmitting={isLoading}
          text={t('buttons.confirm')}
          autoFocus
          onClick={onSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};
