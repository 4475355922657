import React, { FC } from 'react';

import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import { Tooltip } from '@mui/material';
import { useOpenChatOnChatPage } from 'pages/ChatPage/hooks/useOpenChatOnChatPage';
import { useTranslation } from 'react-i18next';

import TrackedIconButton from 'common/components/Buttons/TrackedIconButton/TrackedIconButton';
import { isFeatureEnabled } from 'common/components/FeatureFlags/Feature';
import { useLocationParams } from 'common/hooks/useLocationParams';

export const OpenFullChatButton: FC = () => {
  const { t } = useTranslation('chat');
  const { noteId } = useLocationParams('noteId');
  const { openChatPageChat } = useOpenChatOnChatPage({ noteId });

  const handleOpenChatPage = async () => {
    openChatPageChat();
  };

  if (!isFeatureEnabled('ff-chat-page')) {
    return null;
  }

  return (
    <Tooltip title={t('sideDrawer.button.openInChatPage')}>
      <TrackedIconButton
        disabled={!noteId}
        size="large"
        onClick={handleOpenChatPage}
      >
        <OpenInBrowserIcon />
      </TrackedIconButton>
    </Tooltip>
  );
};
