import React, { FC, useEffect, useState } from 'react';

import { CheckCircle } from '@mui/icons-material';
import { Alert, AlertTitle, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useNoteReportLoading } from 'containers/NotesReportsLoading/NotesReportsLoadingContext';

import { ProgressMarkdownText } from './ProgressMarkdownText';
import { ResearchResult } from './ResearchResult';

interface Props {
  reportNoteId: number;
}

export const MessageWaitReport: FC<Props> = ({ reportNoteId }) => {
  const { t } = useTranslation('chat');
  const { note, status } = useNoteReportLoading(reportNoteId);
  const [progressMessage, setProgressMessage] = useState('');

  useEffect(() => {
    const newProgressMessage =
      note?.content?.split('Report generation in progress\n')[1] || '';

    setProgressMessage(newProgressMessage);
  }, [note]);

  if (status && ['success', 'read'].includes(status)) {
    if (!note) {
      return null;
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2 }}>
        <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
          <CheckCircle
            sx={{
              color: 'success.main',
              fontSize: 24,
            }}
          />
          <Typography
            sx={{ backgroundColor: '#e8f5e9', borderRadius: 1, padding: 1 }}
            variant="body2"
          >
            {t('chat:message.report.status.ready')}
          </Typography>
        </Box>
        <ResearchResult note={note} />
      </Box>
    );
  } else if (status === 'error') {
    return (
      <Alert severity="error">
        <AlertTitle>{t('chat:message.report.status.error')}</AlertTitle>
      </Alert>
    );
  } else {
    return (
      <>
        <Alert icon={false} severity="info">
          <Box sx={{ alignItems: 'center', display: 'flex' }}>
            <ProgressMarkdownText>
              {progressMessage ||
                t('chat:message.report.status.progressDefaultTask')}
            </ProgressMarkdownText>
          </Box>

          <Typography
            color="textSecondary"
            fontStyle="italic"
            sx={{ marginTop: 1 }}
            variant="body2"
          >
            {t('chat:message.report.status.loading')}
          </Typography>
        </Alert>
      </>
    );
  }
};
