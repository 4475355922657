import React, { FC } from 'react';

import { Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { ReferenceTooltip } from '../ReferenceTooltip';
import { TransparentTooltip } from '../TransparentTooltip';

import { useReferenceLink } from './useReferenceLink';

const useStyles = makeStyles({
  colorReferenceLink: {
    textDecoration: 'none',
  },
});

interface Props {
  href: string;
  label: number | string;
  title?: string;
}

export const ReferenceLink: FC<Props> = ({ href, label, title }) => {
  const classes = useStyles();

  const { chipsProps, link, text, tooltipProps } = useReferenceLink(
    `${label}`,
    href
  );

  return (
    <TransparentTooltip
      title={<ReferenceTooltip content={title} href={link} {...tooltipProps} />}
    >
      <Chip
        className={classes.colorReferenceLink}
        label={text}
        size="small"
        sx={{ ml: 0.1, mr: 0.1 }}
        {...chipsProps}
      />
    </TransparentTooltip>
  );
};
