import { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { waitForDocumentIndexing } from 'api/documentsApi/waitForDocumentIndexing';
import { uploadFileOrGetDocument } from 'api/privateDocsApi/uploadOrGetFile';
import { isExist, Nullable } from 'common/utils/assert';
import { useParsedHostname } from 'common/utils/useParsedHostname';

import { selectUser } from '../../User/user.slice';

import { IAttachment } from './IAttachment';

export const useAttachments = () => {
  const { tenant } = useParsedHostname();
  const user = useSelector(selectUser);
  const location = useLocation();

  const [attachments, setAttachments] = useState<Array<Nullable<IAttachment>>>(
    []
  );

  useEffect(() => {
    // TODO: Abort signal for current uploading processes
    setAttachments([]);
  }, [location.pathname]);

  const uploadFile = useCallback(
    async (file: File, index: number) => {
      const attachment: IAttachment = {
        file,
      };
      try {
        setAttachments((prev) => {
          const newAttachments = [...prev];
          attachment.isUploading = true;
          newAttachments[index] = { ...attachment };
          return newAttachments;
        });
        const { data } = await uploadFileOrGetDocument(
          {
            abstractText: '',
            authors: '',
            file: [],
            shareWithOrg: false,
            source: '',
            title: '',
            uri: '',
            year: undefined,
          },
          file,
          tenant,
          user?.useOpenAI
        );

        await waitForDocumentIndexing(
          data.organizeDocId,
          tenant,
          user?.indexCluster
        );

        setAttachments((prev) => {
          const newAttachments = [...prev];
          attachment.data = data;
          newAttachments[index] = { ...attachment };
          return newAttachments;
        });
      } catch (error) {
        setAttachments((prev) => {
          const newAttachments = [...prev];
          attachment.error = error as Error;
          newAttachments[index] = { ...attachment };
          return newAttachments;
        });
      } finally {
        setAttachments((prev) => {
          const newAttachments = [...prev];
          attachment.isUploading = false;
          newAttachments[index] = { ...attachment };
          return newAttachments;
        });
      }
    },
    [tenant, user?.indexCluster, user?.useOpenAI]
  );

  const addAttachments = useCallback(
    async (files: File[]) => {
      const newAttachments = [
        ...attachments,
        ...files.map((file) => ({ file })),
      ];
      newAttachments.forEach((attachment, index) => {
        if (
          !attachment ||
          'isUploading' in attachment ||
          'error' in attachment ||
          'data' in attachment
        ) {
          return;
        }
        uploadFile(attachment.file, index);
      });
    },
    [attachments, uploadFile]
  );

  const removeAttachment = useCallback((index: number) => {
    setAttachments((prev) => {
      const newAttachments = [...prev];
      newAttachments[index] = null;
      return newAttachments;
    });
  }, []);

  const clearAttachments = useCallback(() => {
    setAttachments([]);
  }, []);

  const isUploading = attachments
    .filter(isExist)
    .some((attachment) => attachment.isUploading);

  return {
    addAttachments,
    attachments,
    clearAttachments,
    isUploading,
    removeAttachment,
  };
};
