import React, { FC } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';

import { useStyles } from './styles';

interface BaseProps {
  onClick: () => void;
}

type Props = WithTrackedActionWrappedProps<BaseProps>;

const NewChatButtonComponent: FC<Props> = ({ onAction, onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation('pages');

  const handleClick = () => {
    onAction();
    onClick();
  };

  return (
    <Box className={classes.actions}>
      <Tooltip title={t('chat.startNewChat')}>
        <IconButton
          aria-label={t('chat.startNewChat')}
          color="secondary"
          sx={{ mr: 1, p: 0.5 }}
          onClick={handleClick}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export const NewChatButton = withTrackedAction<
  BaseProps & WithTrackedActionProps
>(NewChatButtonComponent, TrackEventName.NewChatInChatNavigationClicked);
