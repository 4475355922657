import { Undefined } from 'common/utils/assert';
import { wait } from 'common/utils/promise';

import { listDocuments } from '../searchApi';

export const waitForDocumentIndexing = async (
  documentId: string,
  tenant: string,
  indexCluster: Undefined<string>
): Promise<void> => {
  const { data } = await listDocuments({
    docIds: [documentId],
    indexCluster: indexCluster,
    propertyName: 'organize_doc_id',
    tenant,
  });
  const document = data.items.find((doc) => doc.organizeDocId === documentId);
  if (!document) {
    throw new Error('Document not found');
  }
  if (document.status?.title === 'PENDING') {
    await wait(5000);
    return await waitForDocumentIndexing(documentId, tenant, indexCluster);
  }
};
