import { useMemo } from 'react';

import { SearchEngineEnum } from 'common/enums';

import { RetrievalUnitData } from '../../RetrievalUnit/RetrievalUnitData.interface';
import { buildChatContext } from '../helpers';

interface Props {
  docData: RetrievalUnitData;
  docId: string;
  searchEngine?: SearchEngineEnum;
}

export const usePdfChatContext = ({ docData, docId, searchEngine }: Props) => {
  const context = useMemo(
    () =>
      buildChatContext(
        searchEngine ?? SearchEngineEnum.ZetaAlpha,
        docId,
        docData
      ),
    [docData, docId, searchEngine]
  );

  return { context };
};
