import { listNotes } from 'api/notesApi/notesApi';

import { NotesResults } from '../../../SavedNotes/NotesResults/NotesResults.interface';
import { NotesResultsPayload } from '../../../SavedNotes/NotesSearch/NotesSearch.interface';

export const getNotes = async (
  payload: NotesResultsPayload
): Promise<NotesResults> => {
  const { data } = await listNotes(payload);

  if (data.count === 0) {
    return {
      ...payload,
      count: 0,
      items: [],
    };
  }

  return data;
};
