import { useCallback } from 'react';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import {
  BotType,
  DefaultBotTypeMessage,
  QuizbotBotTypeMessage,
  SummaryBotTypeMessage,
  TagOnboardingBotTypeMessage,
} from 'api/tenantSettingsApi/tenantSettingsApi.types';
import { defined, Nullable } from 'common/utils/assert';

import { useBotTypeConfiguration } from './useBotTypeConfiguration';

export interface MessagePayload {
  botParams?: Record<string, any>;
  defaultBotType?: BotType;
  documentsIds?: string[];
  imageBase64?: Nullable<string>;
}

export const useChatMessages = (botType?: BotType) => {
  const { t } = useTranslation(['chat']);
  const { enqueueSnackbar } = useSnackbar();

  const summaryBotConfig = useBotTypeConfiguration(null, 'summary');
  const quizBotConfig = useBotTypeConfiguration(null, 'quizbot');
  const tagBotConfig = useBotTypeConfiguration(null, 'chat_with_tag');

  const buildDefaultMessage = useCallback(
    (message: string, payload?: MessagePayload): DefaultBotTypeMessage => {
      return {
        botParams: payload?.botParams,
        botType: payload?.defaultBotType ?? defined(botType, 'botType'),
        message,
        ...(payload?.imageBase64 ? { image: payload.imageBase64 } : {}),
        ...(payload?.documentsIds
          ? { documentsIds: payload.documentsIds }
          : {}),
      };
    },
    [botType]
  );

  const buildExplainMessage = useCallback(
    (text: string) =>
      buildDefaultMessage(t('chat:explain', { highlight: text })),
    [buildDefaultMessage, t]
  );

  const buildExplainImageMessage = useCallback(
    (imageBase64: string | null) =>
      buildDefaultMessage(t('chat:image'), { imageBase64 }),
    [buildDefaultMessage, t]
  );

  const buildSummaryMessage = useCallback((): SummaryBotTypeMessage => {
    if (!summaryBotConfig.config?.tenantBotType) {
      enqueueSnackbar('Summary bot type is not configured', {
        variant: 'error',
      });
      throw new Error('Summary bot type is not configured');
    }
    return {
      botType: summaryBotConfig.config?.tenantBotType,
      message: t('chat:summary'),
    };
  }, [enqueueSnackbar, summaryBotConfig.config?.tenantBotType, t]);

  const buildQuizMessage = useCallback((): QuizbotBotTypeMessage => {
    if (!quizBotConfig.config?.tenantBotType) {
      enqueueSnackbar('Quiz bot type is not configured', {
        variant: 'error',
      });
      throw new Error('Quiz bot type is not configured');
    }
    return {
      botType: quizBotConfig.config.tenantBotType,
    };
  }, [enqueueSnackbar, quizBotConfig.config?.tenantBotType]);

  const buildTagOnboardingMessage = useCallback(
    (message: string = ''): TagOnboardingBotTypeMessage => {
      if (!tagBotConfig.config?.tenantBotType) {
        enqueueSnackbar('Tag bot type is not configured', {
          variant: 'error',
        });
        throw new Error('Tag bot type is not configured');
      }
      const tagBotType = tagBotConfig.config.tenantBotType;
      return message
        ? {
            botType: tagBotType,
            message,
          }
        : { botType: tagBotType };
    },
    [enqueueSnackbar, tagBotConfig.config?.tenantBotType]
  );

  return {
    buildDefaultMessage,
    buildExplainImageMessage,
    buildExplainMessage,
    buildQuizMessage,
    buildSummaryMessage,
    buildTagOnboardingMessage,
  };
};
